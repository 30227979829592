import styled from 'styled-components'

export const Container = styled.div`
  align-items: center;
  display: flex;
  height: 50px;
  justify-content: center;
  width: 50px;
`

export const Key = styled.button`
  cursor: pointer;
  min-height: 34px;
  margin: 2px;
`

export const KeyboardContainer = styled.div`
  //overflow: scroll;
`
