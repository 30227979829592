import axios from 'axios'
import {
  GET_ALL_SHARED_USERS,
  GET_USER,
  POST_UPDATE_USER_URL,
  TABS_URL,
  UPDATE_ACCORDION_NAME_URL,
  UPDATE_SAVED_TAB,
} from 'apis/urls'
import { User } from 'types'

// ********* Worksheet *********
export const deleteAccordion = (accordionIndex: number, itemIndexInAccordion: number) => {
  return axios.delete(`${TABS_URL}/${accordionIndex}/${itemIndexInAccordion}`)
}

export const updateAccordionName = (accordionId: string, accordionName: string | undefined) => {
  return axios.post(`${UPDATE_ACCORDION_NAME_URL}/${accordionId}`, { accordionName })
}

export const updateSavedTab = (data: any) => {
  return axios.post(UPDATE_SAVED_TAB, data)
}

export const saveTabs = async (tabs: any) => {
  return axios.post(TABS_URL, { tabs })
}

export const savePublicTabs = (workSheet: any) => {
  return axios.post('/api/document/share/public', { workSheet })
}

export const getAllPublicTabs = () => {
  return axios.get('/api/document/shared')
}

export const deletePublicTabs = (id: string) => {
  return axios.delete(`/api/document/share/public/${id}`)
}

export const updatePublicTabs = (id: string, data: any) => {
  return axios.post(`/api/document/share/public/${id}`, data)
}

export const deleteSingleTabFromPublicAccordion = (id: string, tabIndexInAccordion: number) => {
  return axios.delete(`/api/document/share/public/${id}/${tabIndexInAccordion}`)
}

// ********* Users *********
export const updateUser = (data: { email: string, payload: User }) => {
  return axios.post(POST_UPDATE_USER_URL, data)
}

// get specific user information
export const getUser = (id: string) => {
  return axios.get(`${GET_USER}/${id}`)
}

export const getAllSharedUsers = (id: string) => {
  return axios.get(`${GET_ALL_SHARED_USERS}/${id}`)
}
