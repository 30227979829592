import React from 'react'
import { connect } from 'react-redux'
import filedownload from 'js-file-download'

import typemaths from 'apis/typemaths'
import { updateCurrentTabOnly } from 'actions/latexActions'
import PDFlogo from 'logos/download_pdf.svg'
import Latexlogo from 'logos/LaTeX_logo.svg'
import { convertToLatex } from 'utils/latexGenerator'

import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'
import DotLoader from 'react-spinners/DotLoader'

import $ from 'jquery'
import styles from './Download.module.css'

const checkBoxStyles = () => ({
  root: {
    color: '#BDCBFF',
    padding: '0',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#3a3a44',
    },
    '&$checked': {
      color: '#BDCBFF',
      '&:hover': {
        backgroundColor: '#3a3a44',
      },
    },
  },
  checked: {},
})

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox)

class Download extends React.Component {
  state = {
    pageTitle: 'Enter a title',
    pageAuthor: 'Enter an Author',
    currentTabOnly: this.props.latex.currentTabOnly,
  }

  callAPI = async () => {
    let latex
    if (this.props.latex.currentTabOnly) {
      latex = await convertToLatex(
        this.props.latex.pageTitle,
        this.props.latex.pageAuthor,
        {
          [Object.keys(this.props.latex.tabs)[
            this.props.latex.currentTab
          ]]: this.props.latex.tabs[
            Object.keys(this.props.latex.tabs)[this.props.latex.currentTab]
          ],
        },
      )
    } else {
      latex = await convertToLatex(
        this.props.latex.pageTitle,
        this.props.latex.pageAuthor,
        this.props.latex.tabs,
      )
    }
    const data = new URLSearchParams()
    data.append('latex', latex)
    data.append('key', this.props.latex.key)
    const response = await typemaths.post('/api', data)
    return response.data
  }

  onClickDownloadLatex = async () => {
    $('#LatexdownloadBtn').prop('disabled', true)

    $('#spanLatex').toggleClass(styles.hide)
    $('#divLatex').toggleClass(styles.hide)

    if (this.props.latex.currentTabOnly) {
      await filedownload(
        convertToLatex(
          this.props.latex.pageTitle,
          this.props.latex.pageAuthor,
          {
            [Object.keys(this.props.latex.tabs)[
              this.props.latex.currentTab
            ]]: this.props.latex.tabs[
              Object.keys(this.props.latex.tabs)[this.props.latex.currentTab]
            ],
          },
        ),
        `${this.props.latex.pageTitle}.tex`,
      )
    } else {
      await filedownload(
        convertToLatex(
          this.props.latex.pageTitle,
          this.props.latex.pageAuthor,
          this.props.latex.tabs,
        ),
        `${this.props.latex.pageTitle}.tex`,
      )
    }

    $('#spanLatex').toggleClass(styles.hide)
    $('#divLatex').toggleClass(styles.hide)

    $('#LatexdownloadBtn').prop('disabled', false)
  }

  onClickDownloadPDF = async () => {
    $('#PDFdownloadBtn').prop('disabled', true)

    $('#spanPDF').toggleClass(styles.hide)
    $('#divPDF').toggleClass(styles.hide)

    const result = await this.callAPI()
    await filedownload(result, `${this.props.latex.pageTitle}.pdf`)

    $('#spanPDF').toggleClass(styles.hide)
    $('#divPDF').toggleClass(styles.hide)

    $('#PDFdownloadBtn').prop('disabled', false)
  }

  toggleCurrentTabOnly = async () => {
    await this.setState({ currentTabOnly: !this.state.currentTabOnly })
    this.props.updateCurrentTabOnly({
      currentTabOnly: this.state.currentTabOnly,
    })
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.hcontainer}>
          <p className={styles.header}>Download</p>
          <div className={styles.currentTabOnlyContainer}>
            <p className={styles.subheader}>Current Tab Only</p>
            <CustomCheckbox
              checked={this.state.currentTabOnly}
              onChange={this.toggleCurrentTabOnly}
            />
          </div>
        </div>
        <button
          id="PDFdownloadBtn"
          className={styles.btnpdf}
          onClick={this.onClickDownloadPDF}
        >
          <div
            id="spanPDF"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0 5px',
            }}
          >
            <span>Download as PDF</span>
            <img
              src={PDFlogo}
              alt="logo"
              style={{ width: '20px', marginLeft: 'auto' }}
            />
          </div>
          <div id="divPDF" className={styles.hide}>
            <DotLoader
              css="margin: auto;"
              size={25}
              color="#BDCBFF"
              loading
            />
          </div>
        </button>
        <button
          id="LatexdownloadBtn"
          className={styles.btnlatex}
          onClick={this.onClickDownloadLatex}
        >
          <div
            id="spanLatex"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0 5px',
            }}
          >
            <span id="spanLatex">Download as Latex</span>
            <img
              src={Latexlogo}
              alt="logo"
              style={{ width: '30px', marginLeft: 'auto' }}
            />
          </div>
          <div id="divLatex" className={styles.hide}>
            <DotLoader
              css="margin: auto;"
              size={25}
              color="#BDCBFF"
              loading
            />
          </div>
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  latex: state.latex,
})

export default connect(mapStateToProps, { updateCurrentTabOnly })(Download)
