import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import {
  Tab, Tabs, TabList as TabNavigation,
} from 'react-tabs'
import { Dropdown } from 'semantic-ui-react'
import AddIcon from '@material-ui/icons/Add'
import 'react-tabs/style/react-tabs.css'

import {
  addTab,
  removeTab,
  duplicateTab,
  openTabs,
  undo,
  updateCurrentTab,
  updateTabTitle,
  updateSavedTabs,
} from 'actions/latexActions'

import { confirm } from 'components/CustomConfirm/CustomConfirm'
import { updateAllSavedTabs } from 'components/TabList/TabList'

import $ from 'jquery'
import axios from 'axios'
import { ADD_SHARING_TABS_URL } from 'apis/urls'
import sidebarStyles from '../Sidebar/Sidebar.module.css'
import EquationContainer from './EquationContainer/EquationContainer'
import styles from './Main.module.css'
import Instruction from './Instruction/Instruction'
import Keyboard from 'components/Keyboard/Keyboard'
import { KeyboardContainer, StyledIcon, TabPanel } from 'components/pages/Landing/Main/Main.styles'


const CURRENT_YEAR = new Date().getFullYear()

class Main extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isKeyboardOpen: false,
    }
  }

  componentDidMount() {
    const { id } = this.props.match.params
    if (id !== undefined) {
      axios.get(`${ADD_SHARING_TABS_URL}/${id}`)
        .then((res) => {
          this.props.openTabs(res.data.tabs)
        })
        .catch(() => this.props.history.push('/'))
    }

    // undo functionality
    window.addEventListener('keydown', (event) => {
      if (event.ctrlKey && event.key.toLowerCase() === 'z') {
        this.props.undo()
      }
    })
  }

  handleTabSelect = async (index) => {
    this.props.updateCurrentTab({ currentTab: index })
  }

  handleTabRemove = async (tabKey) => {
    const removeTab = async () => {
      await this.props.removeTab({ tabKey })
      if (Object.keys(this.props.latex.tabs)[this.props.latex.currentTab] === undefined) {
        this.props.updateCurrentTab({ currentTab: this.props.currentTab - 1 })
      }
    }

    const globalSavedTabsCopy = [...this.props.globalSavedTabs]
    const updateTabFromNestedArray = (array) => {
      array.forEach((tab, index) => {
        if (Array.isArray(tab)) {
          updateTabFromNestedArray(tab)
        } else if (tab.tabId === tabKey) {
          array[index] = this.props.latex.tabs[tabKey]
        }
      })
      return array
    }

    if (this.props.latex.tabs[tabKey].isChangedAfterSave) {
      const confirmTabRemoveWithSave = await confirm('Would you like to save your edits in this tab?', {
        labels: {
          confirm: 'Save',
        },
      })

      if (confirmTabRemoveWithSave) {
        const updatedTabsAfterSave = updateTabFromNestedArray(globalSavedTabsCopy)
        updateAllSavedTabs(updatedTabsAfterSave)
          .then((res) => {
            this.props.updateSavedTabs(res)
          })
          .catch((err) => console.error(err))
      }
    }
    removeTab()
  }

  handleTabDuplicate = async (tabKey) => {
    this.props.duplicateTab({ tabKey })
    const arr = Object.entries(this.props.latex.tabs)
    const index = arr.findIndex((e) => e[0] === tabKey)
    this.props.updateCurrentTab({ currentTab: index + 1 })
  }

  handleTabRename = async (tabKey) => {
    $(`#${tabKey}`).prop('disabled', false).focus()
  }

  handleAddTab = async () => {
    await this.props.addTab()
    const currentTabIndex = Object.keys(this.props.latex.tabs).length - 1
    this.props.updateCurrentTab({ currentTab: currentTabIndex })
  }

  handleOnChange = async (e, tab) => {
    // if (e.target.value.replace(/\s/g, "").length > 0) {
    await this.props.updateTabTitle({
      tabKey: tab,
      tabTitle: e.target.value,
    })
    $(`#${tab}`).attr('size', this.props.latex.tabs[tab].tabTitle.length === 0 ? 1 : this.props.latex.tabs[tab].tabTitle.length)
    // }
  }

  handleOnBlur = async (e, tab) => {
    if (e.target.value.match(/^ *$/) !== null) {
      await this.props.updateTabTitle({
        tabKey: tab,
        tabTitle: 'Untitled',
      })
    }
    $(`#${tab}`).prop('disabled', true)
  }

  toggleKeyboard = () => {
    this.setState({ isKeyboardOpen: !this.state.isKeyboardOpen })
  }

  renderTab = () => Object.keys(this.props.latex.tabs).map((tab) => (
    <Tab key={tab} className={styles.tabContainer}>
      <div style={{ display: 'flex' }}>
        <div onDoubleClick={() => this.handleTabRename(tab)}>
          <input
            id={tab}
            value={this.props.latex.tabs[tab].tabTitle}
            style={{
              background: 'transparent',
              boxShadow: 'none',
              border: 'none',
              pointerEvents: 'none',
            }}
            className={styles.tabTitle}
            size={this.props.latex.tabs[tab].tabTitle.length}
            onChange={(e) => this.handleOnChange(e, tab)}
            onBlur={(e) => this.handleOnBlur(e, tab)}
          />
        </div>
        <Dropdown className={styles.dropdown}>
          <Dropdown.Menu>
            <Dropdown.Item text="Delete" onClick={() => this.handleTabRemove(tab)} />
            <Dropdown.Item text="Duplicate" onClick={() => this.handleTabDuplicate(tab)} />
            <Dropdown.Item text="Rename" onClick={() => this.handleTabRename(tab)} />
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </Tab>
  ))

  renderTabPanel = () => Object.keys(this.props.latex.tabs).map((tab) => (
    <TabPanel key={tab} $lowHeight={this.state.isKeyboardOpen} style={{ background: '#45454E' }}>
      <EquationContainer
        tabKey={tab}
        equations={this.props.latex.tabs[tab].equations}
      />
    </TabPanel>
  ))

  render() {
    const latexWithoutHistory = { ...this.props.latex }
    delete latexWithoutHistory.history
    localStorage.setItem('latex', JSON.stringify(latexWithoutHistory))

    return (
      <div className={`${styles.container} ${sidebarStyles.stretch}`} id="main" style={{ backgroundColor: this.props.latex.whiteMode ? 'white' : '' }}>
        <Tabs
          selectedIndex={this.props.currentTab}
          className={styles.unselected}
          selectedTabClassName={styles.selected}
          onSelect={this.handleTabSelect}
        >
          <div>
            <TabNavigation style={{ margin: '0', border: 'none' }}>
              {this.renderTab()}
              <div style={{ display: 'inline-flex', position: 'absolute' }}>
                <button onClick={this.handleAddTab} className={styles.btn}>
                  <AddIcon style={{ alignSelf: 'center' }} />
                </button>
              </div>
            </TabNavigation>
          </div>
          {this.renderTabPanel()}
        </Tabs>
        <KeyboardContainer hide={!this.state.isKeyboardOpen}>
          <Keyboard />
        </KeyboardContainer>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'baseline',
          }}
        >
          <p style={{ margin: 'auto', color: 'rgb(69, 69, 78)' }}>
            ©
            {CURRENT_YEAR}
            {' '}
            Brighter Futures Group
          </p>
          <StyledIcon color="grey" name="keyboard" size="large" onClick={this.toggleKeyboard} />
          <Instruction />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  latex: state.latex,
  currentTab: state.latex.currentTab,
  globalSavedTabs: state.latex.savedTabs,
})

export default connect(mapStateToProps, {
  addTab,
  removeTab,
  duplicateTab,
  openTabs,
  undo,
  updateCurrentTab,
  updateTabTitle,
  updateSavedTabs,
})(withRouter(Main))
