import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'

import { openTabs, updateSavedTabs, updateTab } from 'actions/latexActions'
import ShareModal from 'components/ShareModal/ShareModal'
import SingleTabRow from 'components/TabList/SingleTabRow/SingleTabRow'
import { GlobalState, ReduxAction, Tab } from 'types/index'
import {
  deleteAccordion,
  deleteSingleTabFromPublicAccordion,
  updateAccordionName,
  updatePublicTabs,
  updateSavedTab
} from 'apis/requests'
import { usePublicTabsContext } from 'components/Providers/PublicTabsProvider'

interface Props {
  accordionId: string;
  accordionName: string;
  accordionIndex: number;
  isPublic: boolean;
  latex: GlobalState;
  openTabs: ReduxAction;
  removeGroupTabs: () => void;
  tabs: Tab[];
  tabIsOpened: (tab: Tab) => boolean;
  updateSavedTabs: ReduxAction;
  workSheetId?: string;
}

const AccordionTabs = ({
  accordionId,
  accordionName,
  accordionIndex,
  isPublic,
  latex,
  openTabs,
  removeGroupTabs,
  tabs,
  tabIsOpened,
  updateSavedTabs,
  workSheetId
}: Props) => {
  const [ isEditing, setIsEditing ] = useState(false)
  const [ isShareModalOpened, setIsShareModalOpened ] = useState(false)
  const { setPublicTabs } = usePublicTabsContext()

  const ref = useRef({} as HTMLSpanElement)

  useEffect(() => {
    if (isEditing) {
      ref.current.focus()
    }
  }, [isEditing])

  const handleAccordionClick = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement
    target.closest('.tab-list__accordion')?.classList.toggle('tab-list__accordion--expanded')
  }

  const handleCancelAccordionRename = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsEditing(false)
  }

  const handleOpenTabs = (e: React.MouseEvent, tabs: Tab[]) => {
    e.stopPropagation()
    openTabs(tabs)
  }

  const handleSaveAccordionRename = (e: React.MouseEvent) => {
    e.stopPropagation()
    const updatedAccordionName = ref.current.textContent?.trim()

    updateAccordionName(accordionId, updatedAccordionName)
      .then(res => {
        updateSavedTabs(res.data)
        setIsEditing(false)
      })
      .catch(err => console.error(err))
  }

  const handleUpdate = (tabId: string, indexInGroup: number, accordionId: string) => {
    const data = { tabForUpdate: latex.tabs[tabId], indexInGroup, accordionId }

    if (workSheetId) {
      // Use public document api if it is opened publicly
      updatePublicTabs(workSheetId, {
        tabIndexInAccordion: indexInGroup,
        tabForUpdate: latex.tabs[tabId],
      }).then(({ data }) => setPublicTabs(data))
        .catch((err) => console.error(err))
    } else {
      // Use private document api
      updateSavedTab(data)
        .then((res) => {
          updateSavedTabs(res.data)
        })
        .catch((err) => console.error(err))
    }
  }

  const removeTabFromAccordion = (accordionIndex: number, indexInGroup: number) => {
    if (window.confirm('Please confirm if you really want to remove')) {
      // axios.delete remove tab
      if (workSheetId) {
        deleteSingleTabFromPublicAccordion(workSheetId, indexInGroup)
          .then(({ data }) => setPublicTabs(data))
          .catch((err) => console.error(err))
      } else {
        deleteAccordion(accordionIndex, indexInGroup)
          .then((res) => {
            updateSavedTabs(res.data)
          })
          .catch((err) => console.error(err))
      }
    }
  }

  const startEditingAccordionName = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsEditing(true)
  }

  return (
    <div className="tab-list__accordion tab-list__accordion--expanded">
      <div className="tab-list__accordion-summary" onClick={handleAccordionClick}>
        <span
          contentEditable={isEditing}
          ref={ref}
          onClick={(e) => e.stopPropagation()}
        >
          {accordionName}
        </span>
        <div>
          {!isEditing ?
            <>
              <Icon name="pencil alternate" className="single-tab-row__icon" onClick={startEditingAccordionName} />
              <Icon name="share square" className="single-tab-row__icon" onClick={() => setIsShareModalOpened(true)} />
              <Button className="ui primary button" size="mini" positive onClick={(e) => handleOpenTabs(e, tabs)}>
               OPEN
              </Button>
              <Button size="mini" icon onClick={removeGroupTabs}>
                <Icon name="close" />
              </Button>
              <Icon name="chevron down" className="tab-list__dropdown" />
            </> :
            <>
              <Icon name="close" className="single-tab-row__icon" onClick={handleCancelAccordionRename} />
              <Icon name="check" className="single-tab-row__icon" onClick={handleSaveAccordionRename} />
            </>
          }
        </div>
      </div>
      <ShareModal
        onClose={() => setIsShareModalOpened(false)}
        open={isShareModalOpened}
        sharedWorkSheet={{
          accordionName,
          tabs
        }}
      />
      <div className="tab-list__accordion-details">
        {tabs.map((tab, index) => (
          <SingleTabRow
            key={index}
            isChild
            allTabs={latex.tabs}
            isOpened={tabIsOpened(tab)}
            isPublic={isPublic}
            savedTab={tab}
            onOpen={(e: React.MouseEvent) => handleOpenTabs(e, [tab])}
            onDelete={() => removeTabFromAccordion(accordionIndex, index)}
            onUpdate={() => handleUpdate(tab.tabId, index, accordionId)}
          />
        ))}
      </div>
    </div>
  )
}

const mapStateToProps = (state: any) => {
  const currentTabIndex = state.latex.currentTab
  const currentTabKey = Object.keys(state.latex.tabs)[currentTabIndex]
  return {
    currentTab: state.latex.tabs[currentTabKey],
    currentTabKey,
    latex: state.latex,
    user: state.auth.user,
    globalSavedTabs: state.latex.savedTabs,
  }
}

const mapActionsToProps = {
  openTabs,
  updateSavedTabs,
  updateTab,
}

export default connect(mapStateToProps, mapActionsToProps)(AccordionTabs)
