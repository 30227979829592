import React, { useEffect, useState } from 'react'
import {
  Button, Icon, Modal, Input,
} from 'semantic-ui-react'
import axios from 'axios'
import { ADD_SHARING_TABS_URL } from 'apis/urls'
import { v4 as uuidv4 } from 'uuid'
import { Tab, User } from 'types'
import { connect } from 'react-redux'

import './SharedModal.scss'
import { getAllSharedUsers } from 'apis/requests'

type Accordion = {
  accordionId?: string
  accordionName: string
  isAccordion?: boolean
  tabs: Tab[]
}

interface Props {
  currentUser: User
  sharedWorkSheet: Accordion | Tab
  open: boolean
  onClose: () => void
}


const ShareModal =({
  currentUser, sharedWorkSheet, open, onClose,
}: Props) => {
  const [ sharedSuccess, setSharedSuccess ] = useState(false)
  const [ sharedUsers, setSharedUsers ] = useState([])
  const [ email, setEmail ] = useState('')

  useEffect(() => {
    if (open) {
      // reset modal window every time when open
      setEmail('')
      setSharedSuccess(false)

      getAllSharedUsers(currentUser._id).then((res: any) => {
        setSharedUsers(res.data.sharedUsers)
      })
    }
  }, [open])


  const saveSharedItems = () => {
    const copyOfSharedTabs = Object.assign({}, sharedWorkSheet)
    if ('tabs' in copyOfSharedTabs) {
      copyOfSharedTabs.accordionId = uuidv4()
      copyOfSharedTabs.isAccordion = true
      copyOfSharedTabs.tabs.forEach((tab: any) => tab.tabId = uuidv4())
    }
    axios.post(ADD_SHARING_TABS_URL, { workSheet: copyOfSharedTabs, email })
      .then(() => setSharedSuccess(true))
      .catch(() => alert('email does not exist'))
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleModalClose = () => {
    onClose()
    setSharedSuccess(false)
  }

  const renderEmailDataList = () => (
    <datalist id="email-list">
      {sharedUsers.length && sharedUsers.map(({ email }) => (<option key={email} value={email} />))}
    </datalist>
  )

  return (
    <Modal
      onClose={handleModalClose}
      open={open}
      className="shared-modal"
    >
      <div className="shared-modal__upper">
        {currentUser.email}
        <Input list="email-list" className="shared-modal__email-input" placeholder="Type the email you want to share..." value={email} onChange={handleEmailChange} />
        {renderEmailDataList()}
        {sharedSuccess && <p>Shared success</p>}
      </div>

      <div className="shared-modal__bottom">
        <Button color="red" inverted onClick={() => onClose()}>
          <Icon name="remove" />
          {' '}
          Close
        </Button>
        <Button color="green" inverted onClick={saveSharedItems}>
          <Icon name="checkmark" />
          {' '}
          Share
        </Button>
      </div>
    </Modal>
  )
}

const mapStateToProps = (state: any) => ({
  currentUser: state.auth.user,
})

export default connect(mapStateToProps, {})(ShareModal)

