import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Icon } from 'semantic-ui-react'

import {
  addDuplicateEquation,
  addNewEquation,
  removeEquation,
  updateLatexSingle,
  updateShowEqual,
  updateEquationLock,
  updateFocusedEquationKey,
} from 'actions/latexActions'
import { evaluateLatex } from 'utils/latexCalculation'

import $ from 'jquery'

import styles from './SingleSidedEquation.module.css'
import EditableField from 'components/Mathquill/EditableField'
import { useMathquillRefContext } from 'components/Providers/MathquillRefProvider'
import { useMobileDetector } from 'components/Hooks/useMobileDetector'

const SingleSidedEquation = (props) => {
  const [ latexSingle, setLatexSingle ] = useState('')
  const [ showEqual, setShowEqual ] = useState(true)
  const [ lock, setLock ] = useState(false)
  const equationRefs = useMathquillRefContext()
  const isMobile = useMobileDetector()

  useEffect( () => {
    const equation = props.tabs[props.tabKey].equations[
      props.equationKey
    ]
    setLock(equation.lock)
    setLatexSingle(equation.latexSingle)
    setShowEqual(equation.showEqual)
  }, [])

  const onChangeLatexSingle = async (latex) => {
    setLatexSingle(latex)

    await props.updateLatexSingle({
      latexSingle: latex,
      tabKey: props.tabKey,
      equationKey: props.equationKey,
    })

    if (latex[0] === '=' && showEqual === false) {
      await setShowEqual(true)

      await props.updateShowEqual({
        showEqual: showEqual,
        tabKey: props.tabKey,
        equationKey: props.equationKey,
      })
    } else if (latex[0] !== '=' && showEqual === true) {
      await setShowEqual(false)

      await props.updateShowEqual({
        showEqual: showEqual,
        tabKey: props.tabKey,
        equationKey: props.equationKey,
      })
    }
  }

  const onKeyDownMathField = async (event) => {
    const { equationKey, tabKey } = props
    const currentEquation = props.tabs[tabKey].equations[
      equationKey
    ]

    // Calculate the equation
    if (event.ctrlKey && event.shiftKey && event.key === 'Enter') {
      let targetEquationLatex
      if (window.getSelection().toString() !== '') {
        targetEquationLatex = window.getSelection().toString()
      } else {
        targetEquationLatex = currentEquation.latexSingle[0] === '=' ? currentEquation.latexSingle.slice(1) : currentEquation.latexSingle
      }
      evaluateLatex(targetEquationLatex)
        .then(res => {
          const newEquation = Object.assign({}, currentEquation, { latexSingle: res })
          newEquation.latexSingle = `= ${newEquation.latexSingle}`
          props.addNewEquation({
            equationKey,
            newEquation,
            tabKey,
          })
        })
        .catch((err) => {
          console.error(err)
          alert('Cannot evaluate')
        })

      return
    }

    // Add a duplicate equation below
    if (
      event.key === 'Enter'
      && !(event.ctrlKey === true || event.metaKey === true)
    ) {
      await props.addDuplicateEquation({
        tabKey: props.tabKey,
        equationKey: props.equationKey,
      })
    }

    // Switch between single equation and double equation
    if (
      event.key === 'Enter'
      && (event.ctrlKey === true || event.metaKey === true)
    ) {
      const nextElement = event.target.closest('#eq-field')
      await props.onChangeEquationMode(1)
      $(nextElement).find('textarea')[0].focus()
    }

    // when press enter, change state and focus on next line
    if (
      (event.key === 'Enter'
      && !(event.ctrlKey === true || event.metaKey === true))
      || ((event.ctrlKey === true || event.metaKey === true)
      && event.key === 'ArrowDown')
    ) {
      // nextElement is the new created field container
      const nextElement = event.target.closest('#eq-field').nextElementSibling
      const tabPanelContainer = nextElement.parentElement.parentElement
      // TODO: use closest syntax after remove module css
      const onFocusedBottom = nextElement.offsetHeight + nextElement.offsetTop
      const maxVisible = tabPanelContainer.offsetHeight + tabPanelContainer.scrollTop + tabPanelContainer.offsetTop
      if (onFocusedBottom > maxVisible) {
        console.log(onFocusedBottom - maxVisible)
        tabPanelContainer.scrollTop = tabPanelContainer.scrollHeight
      }
      $(nextElement).find('textarea')[0].focus()
    }

    // Delete current line
    if (event.key === 'Escape') {
      const previousElement = event.target.closest('#eq-field')
        .previousElementSibling
      const nextElement = event.target.closest('#eq-field').nextElementSibling
      await props.removeEquation({
        tabKey: props.tabKey,
        equationKey: props.equationKey,
      })
      if (nextElement !== null) {
        $(nextElement).find('textarea')[0].focus()
      } else if (previousElement !== null) {
        $(previousElement).find('textarea')[0].focus()
      }
    }

    if (
      event.key === 'Escape'
      || ((event.ctrlKey === true || event.metaKey === true)
        && event.key === 'ArrowUp')
    ) {
      const previousElement = event.target.closest('#eq-field')
        .previousElementSibling
      if (previousElement !== null) {
        $(previousElement).find('textarea')[0].focus()
      }
    }
  }

  const handleOnFocus = async (e) => {
    props.updateFocusedEquationKey(props.equationKey)
    $(`#${props.equationKey}-eq-checkbox-showequal`).parent().css('color', '#0033cc')
    $(`#${props.equationKey}-eq-checkbox-equationmode`).parent().css('color', '#0033cc')
    $(e.target.closest('#eq-field')).removeClass()
    $(e.target.closest('#eq-field')).addClass(styles.active)
    $(e.target.closest('#text-container')).addClass(styles.activeTextContainer)
  }

  const handleOnBlur = async (e) => {
    $(`#${props.equationKey}-eq-checkbox-showequal`).parent().css('color', '#b9c1d6')
    $(`#${props.equationKey}-eq-checkbox-equationmode`).parent().css('color', '#b9c1d6')
    $(e.target.closest('#eq-field')).removeClass(styles.active)
    $(e.target.closest('#text-container')).removeClass(styles.activeTextContainer)
  }

  const switchLock = (e) => {
    props.updateEquationLock({
      lock: !lock,
      tabKey: props.tabKey,
      equationKey: props.equationKey,
    })

    const textArea = e.target.previousSibling.querySelector('textarea')
    textArea.disabled = !lock
    textArea.closest('div').style.pointerEvents = lock ? 'auto' : 'none'

    if (!textArea.disabled) {
      textArea.focus()
    }

    setLock(prevState => !prevState.lock)
  }

  return (
    <div style={{ width: '100%', display: 'flex' }}>
      <div
        className={styles.textContainer}
        id="text-container"
        style={{
          backgroundColor: props.whiteMode ? 'white' : '',
        }}
      >
        <EditableField
          latex={
            props.tabs[props.tabKey].equations[
              props.equationKey
            ].latexSingle
          }
          onChange={(mathField) => onChangeLatexSingle(mathField)}
          onKeyDown={onKeyDownMathField}
          style={{
            width: '100%',
            display: 'inline-grid',
            border: 'none',
            boxShadow: 'none',
          }}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          readOnly={isMobile}
          ref={el => equationRefs.set(props.equationKey, el)}
        />
        {/*Disable lock button as it has bug and we have undo feature*/}
        {/*{(props.user.paid || props.user.premium) && <Icon style={{ cursor: 'pointer' }} name={lock ? 'lock' : 'unlock'} onClick={switchLock} />}*/}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  tabs: state.latex.tabs,
  whiteMode: state.latex.whiteMode,
  user: state.auth.user,
})

const mapActionsToProps = {
  addDuplicateEquation,
  addNewEquation,
  removeEquation,
  updateLatexSingle,
  updateShowEqual,
  updateEquationLock,
  updateFocusedEquationKey,
}

export default connect(mapStateToProps, mapActionsToProps)(SingleSidedEquation)
