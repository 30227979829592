import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Dropdown, Icon } from 'semantic-ui-react'

import {
  addDuplicateEquation,
  addNewEquation,
  removeEquation,
  updateCurrentSign,
  updateLatexLeft,
  updateLatexRight,
  updateEquationLock, updateFocusedEquationKey,
} from 'actions/latexActions'
import { evaluateLatex } from 'utils/latexCalculation'

import $ from 'jquery'
import styles from '../EquationField.module.css'
import EditableField from 'components/Mathquill/EditableField'
import { useMathquillRefContext } from 'components/Providers/MathquillRefProvider'

const getEditableFieldId = (key, sidePosition) => `${key}-${sidePosition}`

const TwoSidedEquation = (props) => {
  const [ currentSign, setCurrentSign ] = useState('=')
  const [ latexLeft, setLatexLeft ] = useState('')
  const [ latexRight, setLatexRight ] = useState('')
  const [ lock, setLock ] = useState(false)
  const equationRefs = useMathquillRefContext()

  useEffect(() => {
    const equation = props.tabs[props.tabKey].equations[
      props.equationKey
    ]
    setLock(equation.lock)
    setCurrentSign(equation.currentSign)
    setLatexLeft(equation.latexLeft)
    setLatexRight(equation.latexRight)
  }, [])

  const onChangeCurrentSign = async (e, { value }) => {
    const currentElement = $('textarea:focus')
    setCurrentSign(value)
    await props.updateCurrentSign({
      currentSign,
      tabKey: props.tabKey,
      equationKey: props.equationKey,
    })
    if (currentElement.length !== 0) {
      $(currentElement)[0].focus()
    }
  }

  const onChangeLatexLeft = async (latex) => {
    setLatexLeft(latex)
    await props.updateLatexLeft({
      latexLeft: latex,
      tabKey: props.tabKey,
      equationKey: props.equationKey,
    })
  }

  const onChangeLatexRight = async (latex) => {
    setLatexRight(latex)
    await props.updateLatexRight({
      latexRight: latex,
      tabKey: props.tabKey,
      equationKey: props.equationKey,
    })
  }

  const onKeyDownMathField = async (pos, event) => {
    event.persist()
    const { equationKey, tabKey } = props
    const currentEquation = props.tabs[tabKey].equations[
      equationKey
    ]
    const equationType = `latex${pos.charAt(0).toUpperCase() + pos.slice(1)}`

    // Calculate equation
    if (event.ctrlKey && event.shiftKey && event.key === 'Enter') {
      let targetEquationLatex
      if (window.getSelection().toString() !== '') {
        targetEquationLatex = window.getSelection().toString()
      } else {
        targetEquationLatex = currentEquation[equationType]
      }
      evaluateLatex(targetEquationLatex)
        .then(res => {
          const newEquation = Object.assign({}, currentEquation, { [equationType]: res })
          props.addNewEquation({
            equationKey,
            newEquation,
            tabKey,
          })
        })
        .catch((err) => {
          console.error(err)
          alert('Cannot evaluate')
        })

      return
    }
    if (
      event.key === 'Enter'
      && !(event.ctrlKey === true || event.metaKey === true)
    ) {
      await props.addDuplicateEquation({
        tabKey: props.tabKey,
        equationKey: props.equationKey,
      })
    }
    if (
      event.key === 'Enter'
      && (event.ctrlKey === true || event.metaKey === true)
    ) {
      const nextElement = event.target.closest('#eq-field')
      await props.onChangeEquationMode(0)
      $(nextElement).find('textarea')[0].focus()
    }
    if (
      (event.key === 'Enter'
        && !(event.ctrlKey === true || event.metaKey === true))
      || ((event.ctrlKey === true || event.metaKey === true)
        && event.key === 'ArrowDown')
    ) {
      const nextElement = event.target.closest('#eq-field').nextElementSibling
      const tabPanelContainer = nextElement.parentElement.parentElement
      // TODO: use closest syntax after remove module css
      const onFocusedBottom = nextElement.offsetHeight + nextElement.offsetTop
      const maxVisible = tabPanelContainer.offsetHeight + tabPanelContainer.scrollTop + tabPanelContainer.offsetTop
      if (onFocusedBottom > maxVisible) {
        console.log(onFocusedBottom - maxVisible)
        tabPanelContainer.scrollTop = tabPanelContainer.scrollHeight
      }

      if (pos === 'left') {
        $(nextElement).find('textarea')[0].focus()
      } else if (pos === 'right') {
        if ($(nextElement).find('textarea').length === 2) {
          $(nextElement).find('textarea')[1].focus()
        } else {
          $(nextElement).find('textarea')[0].focus()
        }
      }
    }
    if (event.key === 'Escape') {
      const previousElement = event.target.closest('#eq-field')
        .previousElementSibling
      const nextElement = event.target.closest('#eq-field').nextElementSibling
      await props.removeEquation({
        tabKey: props.tabKey,
        equationKey: props.equationKey,
      })
      if (nextElement !== null) {
        if (pos === 'left') {
          $(nextElement).find('textarea')[0].focus()
        } else if (pos === 'right') {
          if ($(nextElement).find('textarea').length === 2) {
            $(nextElement).find('textarea')[1].focus()
          } else {
            $(nextElement).find('textarea')[0].focus()
          }
        }
      } else if (previousElement !== null) {
        if (pos === 'left') {
          $(previousElement).find('textarea')[0].focus()
        } else if (pos === 'right') {
          if ($(previousElement).find('textarea').length === 2) {
            $(previousElement).find('textarea')[1].focus()
          } else {
            $(previousElement).find('textarea')[0].focus()
          }
        }
      }
    }
    if (
      (event.ctrlKey === true || event.metaKey === true)
      && event.key === 'ArrowUp'
    ) {
      const previousElement = event.target.closest('#eq-field')
        .previousElementSibling
      if (previousElement !== null) {
        if (pos === 'left') {
          $(previousElement).find('textarea')[0].focus()
        } else if (pos === 'right') {
          if ($(previousElement).find('textarea').length === 2) {
            $(previousElement).find('textarea')[1].focus()
          } else {
            $(previousElement).find('textarea')[0].focus()
          }
        }
      }
    }
    if (
      (event.ctrlKey === true || event.metaKey === true)
      && event.key === 'ArrowRight'
    ) {
      const nextElement = event.target.parentElement.parentElement.nextElementSibling
      if (nextElement !== null) {
        $(nextElement.nextElementSibling).find('textarea').focus()
      }
    }
    if (
      (event.ctrlKey === true || event.metaKey === true)
      && event.key === 'ArrowLeft'
    ) {
      const previousElement = event.target.parentElement.parentElement.previousElementSibling
      if (previousElement !== null) {
        $(previousElement.previousElementSibling).find('textarea').focus()
      }
    }
  }

  const renderDropdownText = (currentSign) => {
    if (currentSign === '\\leq') {
      return '≤'
    } if (currentSign === '\\geq') {
      return '≥'
    }
    return currentSign
  }

  const handleOnFocus = async (e, sidePosition) => {
    props.updateFocusedEquationKey(getEditableFieldId(props.equationKey, sidePosition))
    $(`#${props.equationKey}-eq-checkbox-showequal`).parent().css('color', '#0033cc')
    $(`#${props.equationKey}-eq-checkbox-equationmode`).parent().css('color', '#0033cc')
    $(e.target.closest('#eq-field')).removeClass()
    $(e.target.closest('#eq-field')).addClass(styles.active)
    $(e.target.closest('#text-container')).addClass(styles.activeTextContainer)
  }

  const handleOnBlur = async (e) => {
    $(`#${props.equationKey}-eq-checkbox-showequal`).parent().css('color', '#b9c1d6')
    $(`#${props.equationKey}-eq-checkbox-equationmode`).parent().css('color', '#b9c1d6')
    $(e.target.closest('#eq-field')).removeClass(styles.active)
    $(e.target.closest('#text-container')).removeClass(styles.activeTextContainer)
  }

  const switchLock = (e) => {
    props.updateEquationLock({
      lock: !lock,
      tabKey: props.tabKey,
      equationKey: props.equationKey,
    })
    const textAreas = e.target.parentElement.querySelectorAll('textarea')
    textAreas.forEach((textArea, i) => {
      textArea.disabled = !lock
      textArea.closest('div').style.pointerEvents = lock ? 'auto' : 'none'
      if (!textArea.disabled && i === 0) {
        textArea.focus()
      }
    })
    setLock(prevState => !prevState.lock)
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        backgroundColor: props.whiteMode ? 'white' : '#B9C1D6',
      }}
    >
      <div
        className={styles.textContainer}
        id="text-container"
        style={{
          backgroundColor: props.whiteMode ? 'white' : '',
        }}
      >
        <EditableField
          latex={
            props.tabs[props.tabKey].equations[
              props.equationKey
            ].latexLeft
          }
          style={{
            width: '50%',
            marginRight: '10px',
            textAlign: 'right',
            display: 'inline-grid',
            minHeight: '30px',
            background: 'transparent',
            border: 'none',
            boxShadow: 'none',
          }}
          onChange={(mathField) => onChangeLatexLeft(mathField)}
          onKeyDown={(e) => onKeyDownMathField('left', e)}
          onFocus={(e) => handleOnFocus(e, 'left')}
          onBlur={handleOnBlur}
          ref={el => equationRefs.set(getEditableFieldId(props.equationKey, 'left'), el)}
        />
        <Dropdown
          onChange={onChangeCurrentSign}
          text={renderDropdownText(currentSign)}
          style={{
            border: 'none',
            background: 'transparent',
            margin: 'auto',
          }}
          compact
          selection
          options={[
            { value: '=', text: '=' },
            { value: '<', text: '<' },
            { value: '\\leq', text: '≤' },
            { value: '>', text: '>' },
            { value: '\\geq', text: '≥' },
          ]}
        />
        <EditableField
          latex={
            props.tabs[props.tabKey].equations[
              props.equationKey
            ].latexRight
          }
          style={{
            width: '50%',
            marginLeft: '10px',
            display: 'inline-grid',
            minHeight: '30px',
            background: 'transparent',
            border: 'none',
            boxShadow: 'none',
            textAlign: 'left',
          }}
          onChange={(mathField) => onChangeLatexRight(mathField)}
          onKeyDown={(e) => onKeyDownMathField('right', e)}
          onFocus={(e) => handleOnFocus(e, 'right')}
          onBlur={handleOnBlur}
          ref={el => equationRefs.set(getEditableFieldId(props.equationKey, 'right'), el)}
        />
        {(props.user.paid || props.user.premium) && <Icon style={{ cursor: 'pointer' }} name={lock ? 'lock' : 'unlock'} onClick={switchLock} />}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  tabs: state.latex.tabs,
  whiteMode: state.latex.whiteMode,
  user: state.auth.user,
})

const mapActionsToProps = {
  addDuplicateEquation,
  addNewEquation,
  removeEquation,
  updateCurrentSign,
  updateLatexLeft,
  updateLatexRight,
  updateEquationLock,
  updateFocusedEquationKey,
}

export default connect(mapStateToProps, mapActionsToProps)(TwoSidedEquation)
