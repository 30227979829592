/** Latex Action Types */
export const ADD_DUPLICATE_EQUATION = 'ADD_DUPLICATE_EQUATION'
export const ADD_NEW_EQUATION = 'ADD_NEW_EQUATION'
export const REMOVE_EQUATION = 'REMOVE_EQUATION'
export const REMOVE_TAB = 'REMOVE_TAB'
export const DUPLICATE_TAB = 'DUPLICATE_TAB'
export const UPDATE_CURRENT_TAB = 'UPDATE_CURRENT_TAB'
export const UPDATE_CURRENT_EQUATION = 'UPDATE_CURRENT_EQUATION'
export const UPDATE_CURRENT_TAB_ONLY = 'UPDATE_CURRENT_TAB_ONLY'
export const ADD_TAB = 'ADD_TAB'
export const UPDATE_CURRENT_SIGN = 'UPDATE_CURRENT_SIGN'
export const UPDATE_EQUATION_LOCK = 'UPDATE_EQUATION_LOCK'
export const UPDATE_EQUATION_MODE = 'UPDATE_EQUATION_MODE'
export const UPDATE_LATEX_LEFT = 'UPDATE_LATEX_LEFT'
export const UPDATE_LATEX_RIGHT = 'UPDATE_LATEX_RIGHT'
export const UPDATE_LATEX_SINGLE = 'UPDATE_LATEX_SINGLE'
export const UPDATE_SHOW_EQUAL = 'UPDATE_SHOW_EQUAL'
export const UPDATE_PAGE_TITLE = 'PAGE_TITLE'
export const UPDATE_PAGE_AUTHOR = 'PAGE_AUTHOR'

/** Auth Action Types */
export const USER_LOADING = 'USER_LOADING'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const GET_REGISTER_ERRORS = 'GET_REGISTER_ERRORS'
export const GET_LOG_IN_ERRORS = 'GET_LOG_IN_ERRORS'
export const GET_CHANGE_PASSWORD_ERRORS = 'GET_CHANGE_PASSWORD_ERRORS'

/** CSS Action Types */
export const CHANGE_THEME = 'CHANGE_THEME'

/** Tab Management */
export const RESET_TABS = 'RESET_TABS'
export const OPEN_TABS = 'OPEN_TABS'
export const UPDATE_SAVED_TABS = 'UPDATE_SAVED_TABS'
export const UPDATE_TAB = 'UPDATE_TAB'
export const UPDATE_TAB_STATUS = 'UPDATE_TAB_STATUS'
export const UPDATE_TAB_TITLE = 'UPDATE_TAB_TITLE'

/** Equation */
export const UPDATE_FOCUSED_EQUATION_KEY = 'UPDATE_CURRENT_EQUATION_KEY'

/** Global */
export const UNDO = 'UNDO'
