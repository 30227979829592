import React, { useEffect, useRef } from 'react'
import MQ from './initMathquill'

interface Props {
  latex: string
}

const StatickField = ({ latex }: Props) => {
  const mathquillRef = useRef<HTMLSpanElement>(null)

  useEffect(() => {
    MQ.StaticMath(mathquillRef.current)
  }, [latex])

  return (
    <span ref={mathquillRef}>{latex}</span>
  )
}

export default StatickField
