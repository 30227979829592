import { debounce } from 'lodash'
import {
  ADD_DUPLICATE_EQUATION,
  ADD_NEW_EQUATION,
  REMOVE_EQUATION,
  ADD_TAB,
  REMOVE_TAB,
  UPDATE_CURRENT_TAB,
  UPDATE_CURRENT_EQUATION,
  DUPLICATE_TAB,
  UPDATE_CURRENT_SIGN,
  UPDATE_EQUATION_MODE,
  UPDATE_CURRENT_TAB_ONLY,
  UPDATE_LATEX_LEFT,
  UPDATE_LATEX_RIGHT,
  UPDATE_LATEX_SINGLE,
  UPDATE_SHOW_EQUAL,
  UPDATE_PAGE_AUTHOR,
  UPDATE_PAGE_TITLE,
  UPDATE_TAB_TITLE,
  RESET_TABS,
  CHANGE_THEME,
  OPEN_TABS,
  UPDATE_SAVED_TABS,
  UPDATE_TAB,
  UPDATE_TAB_STATUS,
} from 'actions/types'

export const HISTORY_ACTION_WHITELIST = [
  ADD_DUPLICATE_EQUATION,
  ADD_NEW_EQUATION,
  REMOVE_EQUATION,
  ADD_TAB,
  REMOVE_TAB,
  UPDATE_CURRENT_TAB,
  UPDATE_CURRENT_EQUATION,
  DUPLICATE_TAB,
  UPDATE_CURRENT_SIGN,
  UPDATE_EQUATION_MODE,
  UPDATE_CURRENT_TAB_ONLY,
  UPDATE_LATEX_LEFT,
  UPDATE_LATEX_RIGHT,
  UPDATE_LATEX_SINGLE,
  UPDATE_SHOW_EQUAL,
  UPDATE_PAGE_AUTHOR,
  UPDATE_PAGE_TITLE,
  UPDATE_TAB_TITLE,
  RESET_TABS,
  CHANGE_THEME,
  OPEN_TABS,
  UPDATE_SAVED_TABS,
  UPDATE_TAB,
]

type History = {
  initiated: boolean,
  // historyIndex: number,
  historyState: object[]
}

export const history: History = {
  initiated: false,
  // historyIndex: 0,
  historyState: [],
}

const historyUpdate = (newState: object, actionType: string) => {
  if (HISTORY_ACTION_WHITELIST.includes(actionType) || !history.initiated) {
    console.log(actionType)
    history.initiated = true
    history.historyState.push(newState)
    // history.historyIndex = history.historyState.length - 1
  }
}

export const undoHistory = () => {
  console.log('undo to previous state')
  if (history.historyState.length > 1) {
    history.historyState.pop()
    return history.historyState.at(-1)
  } else {
    return undefined
  }
}

// Use debounce in case redux state change triggered multiple time
export const addNewStateToHistory = debounce((newState: object, actionType: string)=> {
  historyUpdate(newState, actionType)

  console.log(history.historyState)
}, 20)

