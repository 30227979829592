import { Button, Icon } from 'semantic-ui-react'
import React, { useEffect, useState } from 'react'
import { isEqual } from 'lodash'
import { updateTabStatus } from 'actions/latexActions'
import { connect } from 'react-redux'
import './SingleTabRow.scss'
import { Tab } from 'types/index'

import ShareModal from 'components/ShareModal/ShareModal'

interface Props {
  allTabs: {[key: string]: Tab};
  isChild: boolean;
  savedTab: Tab;
  isOpened: boolean;
  isPublic: boolean;
  onOpen: (e: React.MouseEvent) => void;
  onDelete: () => void;
  onUpdate: () => void;
  updateTabStatus: (payload: {tabKey: string, isTabChanged: boolean}) => void;
  user?: any
}

const SingleTabRow = ({
  isChild, allTabs, savedTab, isOpened, isPublic, onOpen, onDelete, onUpdate, updateTabStatus, user
}: Props) => {
  const [ isChanged, setIsChanged ] = useState(false)
  const [ editable, setEditable ] = useState(true)
  const [ isModalOpened, setIsModalOpened ] = useState(false)
  const [ isSavedTabOnWorkSheet, setIsSavedTabOnWorkSheet ] = useState(false)
  const [ openedSavedTab, setOpenedSavedTab ] = useState({} as Tab)

  useEffect(() => {
    if (allTabs[savedTab.tabId] !== undefined) {
      setOpenedSavedTab(allTabs[savedTab.tabId])
      setIsSavedTabOnWorkSheet(true)
    } else {
      setOpenedSavedTab({} as Tab)
      setIsSavedTabOnWorkSheet(false)
    }
  }, [allTabs])

  // TODO: user is not updated on time
  useEffect(() => {
    if (isPublic && savedTab.author !== user.email) {
      setEditable(false)
    } else {
      setEditable(true)
    }
  }, [user])

  // Check if saved tab is equal to opened tab
  useEffect(() => {
    if (isSavedTabOnWorkSheet &&
        openedSavedTab.tabId === savedTab.tabId &&
        (!isEqual(openedSavedTab.tabTitle, savedTab.tabTitle) || !isEqual(openedSavedTab.equations, savedTab.equations))) {
      setIsChanged(true)
      updateTabStatus({
        tabKey: savedTab.tabId,
        isTabChanged: true,
      })
    } else {
      setIsChanged(false)
      updateTabStatus({
        tabKey: savedTab.tabId,
        isTabChanged: false,
      })
    }
  }, [ openedSavedTab, isSavedTabOnWorkSheet, savedTab ])

  return (
    <div className={`tab-list__saved-tab ${isChild ? 'tab-list__saved-tab--children' : ''} ${isOpened ? 'tab-list__saved-tab--is-opened' : ''}`}>
      <span className="tabItem">{savedTab.tabTitle}</span>

      <div>
        {!isPublic && <Icon name="share square" className="single-tab-row__icon" onClick={() => setIsModalOpened(true)} />}
        {isChanged && editable
          ? (
            <Button className="ui primary button" size="mini" onClick={onUpdate}>
              UPDATE
            </Button>
          )
          : !isOpened && (
            <Button className="ui primary button" size="mini" positive onClick={onOpen}>
              OPEN
            </Button>
          )}
        <Button size="mini" icon onClick={onDelete}>
          <Icon name="close" />
        </Button>
      </div>

      <ShareModal
        onClose={() => setIsModalOpened(false)}
        open={isModalOpened}
        sharedWorkSheet={savedTab}
      />
    </div>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
  }
}

const mapActionsToProps = {
  updateTabStatus,
}

export default connect(mapStateToProps, mapActionsToProps)(SingleTabRow)
