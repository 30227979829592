import { combineReducers } from 'redux'

import latexReducer from './latexReducer'
import authReducer from './authReducer'
import errorReducer from './errorReducer'

export default combineReducers({
  latex: latexReducer,
  auth: authReducer,
  errors: errorReducer,
})
