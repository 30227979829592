import React from 'react'
import { connect } from 'react-redux'
import {
  updatePageAuthor,
  updatePageTitle,
  resetTabs,
} from 'actions/latexActions'

import {
  Modal, Header, Icon, Button, Segment,
} from 'semantic-ui-react'

import $ from 'jquery'

import styles from './DocumentDetails.module.css'
import './DocumentDetails.scss'
import TabList from 'components/TabList/TabList'

class DocumentDetails extends React.Component {
  state = {
    pageTitle: 'Enter a title',
    pageAuthor: 'Enter an Author',
    open: false,
  }

  async componentDidMount() {
    await this.setState({
      pageTitle: this.props.pageTitle,
      pageAuthor: this.props.pageAuthor,
    })
  }

  onChangePageTitle = async (event) => {
    this.setState({ pageTitle: event.target.value })
    await this.props.updatePageTitle(event.target.value)
  }

  onBlurPageTitle = async (event) => {
    if (event.target.value.match(/^ *$/) !== null) {
      this.setState({ pageTitle: 'Enter a title' })
      await this.props.updatePageTitle('Enter a title')
    }
  }

  onChangePageAuthor = async (event) => {
    this.setState({ pageAuthor: event.target.value })
    await this.props.updatePageAuthor(event.target.value)
  }

  onBlurPageAuthor = async (event) => {
    if (event.target.value.match(/^ *$/) !== null) {
      this.setState({ pageAuthor: 'Enter an author' })
      await this.props.updatePageAuthor('Enter an author')
    }
  }

  handleOnClickReset = async () => {
    await this.props.resetTabs()
    await this.setState({ open: false })
    $('#react-tabs-0').click()
  }

  render() {
    return (
      <div className={styles.container}>
        <div style={{ display: 'flex', marginBottom: '20px' }}>
          <p className={styles.header}>Document Details</p>
          <Modal
            basic
            onClose={() => this.setState({ open: false })}
            onOpen={() => this.setState({ open: true })}
            open={this.state.open}
            size="small"
            trigger={(
              <button
                className="btn__reset"
              >
                Reset Tabs
              </button>
            )}
          >
            <Header icon>
              <Icon name="undo" />
              Reset Tabs
            </Header>
            <Modal.Content>
              <p>
                Are you sure you want to reset your tabs?
              </p>
            </Modal.Content>
            <Modal.Actions>
              <Button basic color="red" inverted onClick={() => this.setState({ open: false })}>
                <Icon name="remove" />
                {' '}
                No
              </Button>
              <Button color="green" inverted onClick={this.handleOnClickReset}>
                <Icon name="checkmark" />
                {' '}
                Yes
              </Button>
            </Modal.Actions>
          </Modal>
        </div>
        <div className={styles.inputcontainer}>
          <p className={styles.subheader}>Title</p>
          <input
            required
            className={styles.input}
            placeholder={this.state.pageTitle}
            onBlur={this.onBlurPageTitle}
            onChange={this.onChangePageTitle}
          />
        </div>
        <div className={styles.inputcontainer}>
          <p className={styles.subheader}>Author</p>
          <input
            required
            className={styles.input}
            placeholder={this.state.pageAuthor}
            onBlur={this.onBlurPageAuthor}
            onChange={this.onChangePageAuthor}
          />
        </div>

        <Segment inverted>
          <TabList />
        </Segment>

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const currentTabIndex = state.latex.currentTab
  const currentTabId = Object.keys(state.latex.tabs)[currentTabIndex]
  return {
    pageAuthor: state.latex.pageAuthor,
    pageTitle: state.latex.pageTitle,
    currentTab: state.latex.tabs[currentTabId],
    allTabs: state.latex.tabs,
    latex: state.latex,
    user: state.auth.user,
  }
}

const mapActionsToProps = {
  updatePageAuthor,
  updatePageTitle,
  resetTabs,
}

export default connect(mapStateToProps, mapActionsToProps)(DocumentDetails)
