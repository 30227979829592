// document
export const ADD_SHARING_TABS_URL = '/api/document/share'
export const TABS_URL = '/api/document'
export const UPDATE_ACCORDION_NAME_URL = '/api/document/accordion'
export const UPDATE_ALL_SAVED_TABS_URL = '/api/document/update/all-saved'
export const UPDATE_SAVED_TAB = '/api/document/update'

// users
export const GET_USER = '/api/users'
export const GET_ALL_USERS = '/api/users/all'
export const POST_UPDATE_USER_URL = '/api/users/update'
export const GET_ALL_SHARED_USERS = '/api/users/shared'
