import React, { createContext, useContext, useRef } from 'react'

const MathquillRefContext = createContext(new Map())

export const MathquillRefProvider = ({ children }: React.PropsWithChildren<any>) => {
  const fieldsRefs = useRef(new Map())
  return <MathquillRefContext.Provider value={fieldsRefs.current}>{children}</MathquillRefContext.Provider>
}

export const useMathquillRefContext = () => useContext(MathquillRefContext)
