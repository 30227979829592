import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from 'react'
import { MathField as MathFieldType } from 'mathquill-node'
import MQ from './initMathquill'
import { debounce } from 'lodash'

interface Props {
  id?: string
  latex: string
  onBlur?: (e: React.FocusEvent) => void
  onChange: (s: string) => void
  onFocus?: (e: React.FocusEvent) => void
  props?: React.Props<any>
  readOnly?: boolean
}

const EditableField = forwardRef(
  ({ latex, onChange, readOnly, ...props }: Props, ref) => {
    const [ internalLatex, setInternalLatex ] = useState(latex)
    const [ mathField, setMathField ] = useState({} as MathFieldType)

    const inputRef = useRef<HTMLSpanElement>(null)
    const isTypingRef = useRef(false)

    useEffect(() => {
      // Use debounce to prevent handleChange function triggered twice
      const handleChange = debounce((latexInput: string) => {
        setInternalLatex(latexInput)
        if (!isTypingRef.current) {
          // any code that when is not typing sit here. e.g. undo
        } else {
          // any code that only get called when typing
          onChange(initMQ.latex())
        }
        isTypingRef.current = true
      }, 0, { leading: true, trailing: false })

      const inputNode = inputRef.current
      const initMQ = MQ.MathField(inputNode, {
        handlers: {
          edit: () => {
            handleChange(initMQ.latex())
          },
        },
      })

      setMathField(initMQ)

      // initial latex
      initMQ.latex(latex)
    }, [])

    // Control of readOnly of mathquill instance
    useEffect(() => {
      if (inputRef.current === null) {
        return
      }
      const mathTextarea = inputRef.current.querySelector('.mq-textarea textarea') as HTMLTextAreaElement
      mathTextarea.readOnly = !!readOnly
    }, [readOnly])

    // When change state directly
    useEffect(() => {
      if (mathField.latex && latex !== internalLatex) {
        isTypingRef.current = false
        mathField.latex(latex)
      }
    }, [latex])

    useImperativeHandle(ref, () => ({
      cmd: (str: string) => {
        mathField.cmd(str)
      },
      focus: () => {
        mathField.focus()
      },
      el: () => {
        return mathField.el()
      },
      // Refer keyInput https://www.w3.org/TR/2012/WD-DOM-Level-3-Events-20120614/#fixed-virtual-key-codes
      keystroke: (keyInput: string) => {
        mathField.keystroke(keyInput)
      },
      latex: () => {
        return mathField.latex()
      }
    }))

    return (
      <span
        ref={inputRef}
        {...props}
      />
    )
  }
)
export default EditableField
