import React from 'react'
import { Button, Popup } from 'semantic-ui-react'
import { connect } from 'react-redux'
import StaticField from 'components/Mathquill/StatickField'
import Key from './Key'
import { useMathquillRefContext } from 'components/Providers/MathquillRefProvider'
import { addDuplicateEquation, addNewEquation, removeEquation } from 'actions/latexActions'
import { evaluateLatex } from 'utils/latexCalculation'
import { KeyboardContainer } from 'components/Keyboard/Keyboard.styles'

interface Props {
  addDuplicateEquation: any
  addNewEquation: any
  currentFocusedEquationKey: string
  currentTabKey: string
  removeEquation: any
  tabs: any
}

const Keyboard = ({ addDuplicateEquation, addNewEquation, currentFocusedEquationKey, currentTabKey, removeEquation, tabs }: Props) => {
  const equationRefs = useMathquillRefContext()


  const handleCmd = (latex: string[]) => () => {
    const focusedEquationField = equationRefs.get(currentFocusedEquationKey)
    for (let i = 0; i < latex.length; i++) {
      focusedEquationField.cmd(latex[i])
    }
    focusedEquationField.focus()
  }

  const handleKeystroke = (keyInput: string) => () => {
    const focusedEquationField = equationRefs.get(currentFocusedEquationKey)
    focusedEquationField.keystroke(keyInput)
    focusedEquationField.focus()
  }

  const addNewLine = () => {
    addDuplicateEquation({
      tabKey: currentTabKey,
      equationKey: currentFocusedEquationKey,
    })
  }

  const removeLine = () => {
    removeEquation({
      tabKey: currentTabKey,
      equationKey: currentFocusedEquationKey,
    })
  }

  // TODO: Cannot evaluate double side equation
  const calculateLatex = () => {
    const currentEquation = tabs[currentTabKey].equations[currentFocusedEquationKey]
    const targetEquationLatex = equationRefs.get(currentFocusedEquationKey).latex()
    evaluateLatex(targetEquationLatex)
      .then(res => {
        if (!currentEquation) {
          throw new Error('Cannot evaluate')
        }
        const newEquation = Object.assign({}, currentEquation, { latexSingle: res })
        newEquation.latexSingle = `= ${newEquation.latexSingle}`
        addNewEquation({
          equationKey: currentFocusedEquationKey,
          newEquation,
          tabKey: currentTabKey,
        })
      })
      .catch((err) => {
        console.error(err)
        alert('Cannot evaluate')
      })
  }

  const renderKeyboard = () => {
    return (
      <>
        <Key onClick={handleCmd(['^'])}>
          <StaticField latex={'x^n'} />
        </Key>
        <Key onClick={handleCmd(['log'])}>
          <StaticField latex={'log'} />
        </Key>

        <Key onClick={handleCmd(['\\frac'])}>
          <StaticField latex={'\\frac{}{}'} />
        </Key>
        <Key onClick={handleCmd(['\\sqrt'])}>
          <StaticField latex={'\\sqrt{}'} />
        </Key>
        <Key onClick={handleCmd(['\\ln'])}>
          <StaticField latex={'\\ln'} />
        </Key>
        <Key onClick={handleCmd(['\\int'])}>
          <StaticField latex={'\\int'} />
        </Key>

        <br/>

        <Key onClick={handleCmd(['\\degree'])}>
          <StaticField latex={'\\degree'} />
        </Key>
        <Key onClick={handleCmd(['\\sin'])}>
          <StaticField latex={'\\sin'} />
        </Key>
        <Key onClick={handleCmd(['\\cos'])}>
          <StaticField latex={'\\cos'} />
        </Key>
        <Key onClick={handleCmd(['\\tan'])}>
          <StaticField latex={'\\tan'} />
        </Key>

        <Key onClick={handleCmd(['\('])}>
          <StaticField latex={'\('} />
        </Key>
        <Key onClick={handleCmd(['\)'])}>
          <StaticField latex={'\)'} />
        </Key>
        <Key onClick={handleCmd(['\\nthroot'])}>
          <StaticField latex={'\\nthroot{}{}'} />
        </Key>

        <br/>

        <Key onClick={handleCmd(['7'])}>
          <StaticField latex={'7'} />
        </Key>
        <Key onClick={handleCmd(['8'])}>
          <StaticField latex={'8'} />
        </Key>
        <Key onClick={handleCmd(['9'])}>
          <StaticField latex={'9'} />
        </Key>
        <Key onClick={handleKeystroke('Backspace')}>
          <span>Backspace</span>
        </Key>
        <Key onClick={handleCmd(['+'])}>
          <span>+</span>
        </Key>
        <Key onClick={handleCmd(['-'])}>
          <span>-</span>
        </Key>
        <Key onClick={addNewLine}>
          <span>New Line</span>
        </Key>
        <Key onClick={handleCmd(['\\text'])}>
          <span>text</span>
        </Key>

        <br/>

        <Key onClick={handleCmd(['4'])}>
          <StaticField latex={'4'} />
        </Key>
        <Key onClick={handleCmd(['5'])}>
          <StaticField latex={'5'} />
        </Key>
        <Key onClick={handleCmd(['6'])}>
          <StaticField latex={'6'} />
        </Key>
        <Key onClick={handleCmd(['*'])}>
          <StaticField latex={'\\times'} />
        </Key>
        <Key onClick={handleCmd(['\\div'])}>
          <StaticField latex={'\\div'} />
        </Key>
        <Key onClick={removeLine}>
          <span>Delete Line</span>
        </Key>

        <br/>

        <Key onClick={handleCmd(['1'])}>
          <StaticField latex={'1'} />
        </Key>
        <Key onClick={handleCmd(['2'])}>
          <StaticField latex={'2'} />
        </Key>
        <Key onClick={handleCmd(['3'])}>
          <StaticField latex={'3'} />
        </Key>
        <Key onClick={calculateLatex}>
          <span>=</span>
        </Key>

        <br/>

        <Key onClick={handleCmd(['0'])}>
          <StaticField latex={'0'} />
        </Key>
        <Key onClick={handleCmd(['.'])}>
          <StaticField latex={'.'} />
        </Key>
        <Key onClick={handleCmd(['x'])}>
          <StaticField latex={'x'} />
        </Key>
        <Key onClick={handleCmd(['\\pi'])}>
          <StaticField latex={'\\pi'} />
        </Key>
        <Key onClick={handleCmd(['\\theta'])}>
          <StaticField latex={'\\theta'} />
        </Key>
        <Key onClick={handleCmd(['\\delta'])}>
          <StaticField latex={'\\delta'} />
        </Key>
        <Key onClick={handleCmd(['\\phi'])}>
          <StaticField latex={'\\phi'} />
        </Key>
      </>
    )
  }

  return (
    <KeyboardContainer onMouseDown={(e:any) => e.preventDefault()}>
      {/*<Popup*/}
      {/*  trigger={<Button circular style={{ background: '#DDEAFF' }} icon="info" />}*/}
      {/*  content={renderKeyboard()}*/}
      {/*  on="click"*/}
      {/*/>*/}
      {renderKeyboard()}
    </KeyboardContainer>
  )
}

const mapStateToProps = (state: any) => ({
  currentFocusedEquationKey: state.latex.currentEquationKey as string,
  currentTabKey: Object.keys(state.latex.tabs)[state.latex.currentTab],
  tabs: state.latex.tabs,
})

const mapActionsToProps = {
  addDuplicateEquation,
  addNewEquation,
  removeEquation,
}

export default connect(mapStateToProps, mapActionsToProps)(Keyboard)
