import evaluatex from 'evaluatex/dist/evaluatex'

export const renameObjectKey = (targetObject, targetKeyName, newKeyName) => {
  const newObject = {}
  for (const [ key, value ] of Object.entries(targetObject)) {
    if (key === targetKeyName) {
      newObject[newKeyName] = value
    } else {
      newObject[key] = value
    }
  }
  return newObject
}
