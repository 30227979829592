import React, { createContext, useContext, useEffect, useState } from 'react'
import { getAllPublicTabs } from 'apis/requests'

const PublicTabsContext = createContext({
  publicTabs: null,
  setPublicTabs:  null as any,
})

export const PublicTabsProvider = ({ children }: any) => {
  const [ publicTabs, setPublicTabs ] = useState<any>([])

  useEffect(() => {
    getAllPublicTabs()
      .then((res: any) => {
        setPublicTabs(res.data)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }, [])

  return <PublicTabsContext.Provider value={{ publicTabs, setPublicTabs }}>{children}</PublicTabsContext.Provider>
}

export const usePublicTabsContext = () => useContext(PublicTabsContext)
