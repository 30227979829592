import React from 'react'
import UserList from 'components/UserList/UserList'
import './AdminPage.scss'

function AdminPage() {
  return (
    <div className="admin-page">
      <UserList />
    </div>
  )
}

export default AdminPage
