import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import $ from 'jquery'
import EquationField from './EquationField/EquationField'

const EquationContainer = (props) => {
  useEffect(() => {
    $('#eq-container')
      .find('textarea')
      [props.tabs[props.tabKey].currentEquation].focus()
  }, [])

  const renderEquations = () => {
    let counter = 0
    return Object.keys(props.equations).map((equation) => {
      if (
        props.equations[equation].equationMode === 1
        || props.equations[equation].showEqual === true
      ) {
        counter += 1
        return (
          <EquationField
            key={equation}
            counter={counter}
            tabKey={props.tabKey}
            equationKey={equation}
            equationMode={props.equations[equation].equationMode}
            showEqual={props.equations[equation].showEqual}
          />
        )
      }
      return (
        <EquationField
          key={equation}
          tabKey={props.tabKey}
          equationKey={equation}
          equationMode={props.equations[equation].equationMode}
          showEqual={props.equations[equation].showEqual}
        />
      )
    })
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: props.whiteMode ? 'white' : '',
      }}
      id="eq-container"
    >
      {renderEquations()}
    </div>
  )
}

const mapStateToProps = (state) => ({
  tabs: state.latex.tabs,
  whiteMode: state.latex.whiteMode,
})

export default connect(mapStateToProps, {})(
  EquationContainer,
)
