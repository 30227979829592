import React, { useEffect } from 'react'
import axios from 'axios'
import { TABS_URL, UPDATE_ALL_SAVED_TABS_URL } from 'apis/urls'
import { connect } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import './TabList.scss'
import { renameObjectKey } from 'utils/common'
import SingleTabRow from './SingleTabRow/SingleTabRow'
import { openTabs, updateSavedTabs, updateTab } from '../../actions/latexActions'
import AccordionTabs from 'components/Accordion/AccordionTabs'
import { saveTabs } from 'apis/requests'

export const updateAllSavedTabs = async (tabs) => {
  const res = await axios.post(UPDATE_ALL_SAVED_TABS_URL, { savedTabs: tabs })
  if (!res.data) {
    throw new Error('HTTP error!')
  }
  return res.data
}

function TabList({
  openTabs, user, currentTab, latex, currentTabKey, updateSavedTabs, updateTab, globalSavedTabs,
}) {
  // Fetch all saved tabs from database on mount
  useEffect(() => {
    if (Object.keys(user).length) {
      fetchSavedTabs()
    }
  }, [user])

  const fetchSavedTabs = () => {
    axios.get(TABS_URL)
      .then((res) => {
        if (res.data.savedTabs) {
          updateSavedTabs(res.data.savedTabs)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const removeTabOrTabs = (index) => {
    if (window.confirm('Please confirm if you really want to remove')) {
      axios.delete(`${TABS_URL}/${index}`)
        .then((res) => updateSavedTabs(res.data))
        .catch((err) => console.error(err))
    }
  }

  const removeGroupTabs = (e, index) => {
    e.stopPropagation()
    removeTabOrTabs(index)
  }

  const saveCurrentTab = () => {
    const newTabId = uuidv4()
    const tabForSaving = { ...currentTab, tabId: newTabId }
    const updatedLatexTabs = { ...renameObjectKey(latex.tabs, currentTabKey, newTabId) }
    updatedLatexTabs[newTabId].tabId = newTabId
    updateTab(updatedLatexTabs)
    saveTabs(tabForSaving)
      .then(({ data }) => updateSavedTabs(data))
      .catch(() => console.error('Save current tab to DB going wrong'))
  }

  const saveAllTabs = async () => {
    const updatedLatexTabs = {}
    const tabsForSaving = Object.keys(latex.tabs).map((tabId) => {
      const newTabId = uuidv4()
      updatedLatexTabs[newTabId] = { ...latex.tabs[tabId], tabId: newTabId }
      return { ...latex.tabs[tabId], tabId: newTabId }
    })
    saveTabs({
      accordionName: 'New accordion',
      accordionId: uuidv4(),
      isAccordion: true,
      tabs: tabsForSaving
    })
      .then(({ data }) => {
        updateSavedTabs(data)
        updateTab(updatedLatexTabs)
      })
      .catch(() => console.error('Save all tabs to DB going wrong'))
  }

  const handleOpenTabs = (e, tabs) => {
    e.stopPropagation()
    openTabs(tabs)
  }

  const handleUpdate = (tabId, indexInGroup, accordionId) => {
    const data = { tabForUpdate: latex.tabs[tabId], indexInGroup }
    if (accordionId) {
      Object.assign(data, { accordionId })
    }

    axios.post('/api/document/update', data)
      .then((res) => {
        updateSavedTabs(res.data)
      })
      .catch((err) => console.error(err))
  }

  const tabIsOpened = (tab) => tab.tabId === currentTab?.tabId

  return (
    <>
      {(user.paid || user.premium)
      && (
        <div className="tab-list__actions">
          <button className="btn btn__primary" onClick={saveCurrentTab}>Save current tab</button>
          <button className="btn btn__primary" onClick={saveAllTabs}>Save All</button>
        </div>
      )}

      {globalSavedTabs && globalSavedTabs.map((item, index) => (
        item.isAccordion
          ?
          <AccordionTabs
            accordionId={item.accordionId}
            accordionName={item.accordionName}
            accordionIndex={index}
            key={index}
            removeGroupTabs={e => removeGroupTabs(e, index)}
            tabs={item.tabs}
            tabIsOpened={tabIsOpened}
          />
          : (
            <SingleTabRow
              key={index}
              allTabs={latex.tabs}
              isOpened={tabIsOpened(item)}
              savedTab={item}
              onOpen={(e) => handleOpenTabs(e, [item])}
              onDelete={() => removeTabOrTabs(index)}
              onUpdate={() => handleUpdate(item.tabId, index)}
            />
          )
      ))}
    </>
  )
}

const mapStateToProps = (state) => {
  const currentTabIndex = state.latex.currentTab
  const currentTabKey = Object.keys(state.latex.tabs)[currentTabIndex]
  return {
    currentTab: state.latex.tabs[currentTabKey],
    currentTabKey,
    latex: state.latex,
    user: state.auth.user,
    globalSavedTabs: state.latex.savedTabs,
  }
}

const mapActionsToProps = {
  openTabs,
  updateSavedTabs,
  updateTab,
}

export default connect(mapStateToProps, mapActionsToProps)(TabList)
