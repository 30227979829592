import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Modal } from 'semantic-ui-react'

import { registerUser, loginUser } from 'actions/authActions'

import classnames from 'classnames'

import './Register.scss'

class Register extends Component {
  constructor() {
    super()
    this.state = {
      name: '',
      email: '',
      password: '',
      password2: '',
      errors: {},
      open: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      })
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  onSubmit = async (e) => {
    e.preventDefault()
    const newUser = {
      name: this.state.name,
      email: this.state.email,
      password: this.state.password,
      password2: this.state.password2,
    }

    this.props.registerUser(newUser).then((res) => {
      if (res.status === 200) {
        this.props.loginUser({
          email: newUser.email,
          password: newUser.password,
        })
      }
    })
  }

  onClose = () => {
    this.setState({
      name: '',
      email: '',
      password: '',
      password2: '',
      errors: {},
      open: false,
    })
  }

  render() {
    const { errors } = this.state
    return (
      <Modal
        onClose={this.onClose}
        onOpen={() => this.setState({ open: true })}
        open={this.state.open}
        trigger={<button className="btn btn__primary register__btn">Register</button>}
      >
        <div>
          <form
            className="ui form"
            noValidate
            onSubmit={this.onSubmit}
            style={{ margin: '70px' }}
          >
            <h4 className="ui dividing header">Register</h4>
            <div className="field">
              <label>Name</label>
              <input
                onChange={this.onChange}
                value={this.state.name}
                error={errors.name}
                id="name"
                type="text"
                className={classnames('', {
                  invalid: errors.name,
                })}
              />
              <span className="red-text">{errors.name}</span>
            </div>
            <div className="field">
              <label>Email</label>
              <input
                onChange={this.onChange}
                value={this.state.email}
                error={errors.email}
                id="email"
                type="email"
                className={classnames('', {
                  invalid: errors.email,
                })}
              />
              <span className="red-text">{errors.email}</span>
            </div>
            <div className="field">
              <label>Password</label>
              <input
                onChange={this.onChange}
                value={this.state.password}
                error={errors.password}
                id="password"
                type="password"
                className={classnames('', {
                  invalid: errors.password,
                })}
              />
              <span className="red-text">{errors.password}</span>
            </div>
            <div className="field">
              <label>Confirm Password</label>
              <input
                onChange={this.onChange}
                value={this.state.password2}
                error={errors.password2}
                id="password2"
                type="password"
                className={classnames('', {
                  invalid: errors.password2,
                })}
              />
              <span className="red-text">{errors.password2}</span>
            </div>
            <div>
              <button
                style={{
                  width: '150px',
                  borderRadius: '3px',
                  letterSpacing: '1.5px',
                  marginTop: '1rem',
                }}
                type="submit"
                className="ui button"
              >
                Sign up
              </button>
            </div>
          </form>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  // errors: state.errors.register,
})

export default connect(mapStateToProps, { loginUser, registerUser })(Register)
