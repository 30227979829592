import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'

import jwt_decode from 'jwt-decode'
import setAuthToken from 'utils/setAuthToken'
import { setCurrentUser, logoutUser } from 'actions/authActions'
import store from './store'

import Navbar from './components/layout/Navbar'
import Landing from './components/pages/Landing/Landing'

import './App.css'
import './scss/app.scss'

import AdminPage from './pages/AdminPage'
import 'mathquill-node/lib/mathquill.css'
import { MathquillRefProvider } from './components/Providers/MathquillRefProvider'
import { getUser } from 'apis/requests'
import { PublicTabsProvider } from 'components/Providers/PublicTabsProvider'

const App = () => {
  const token = localStorage.jwtToken
  if (token) {
    setAuthToken(token)
  }
  useEffect(() => {
    const fetchUser = async () => {
      if (token) {
        const decoded = jwt_decode(token)
        const res = await getUser(decoded.id)
        if (res.status === 200) {
          store.dispatch(setCurrentUser(res.data.user))
        }
        const currentTime = Date.now() / 1000
        if (decoded.exp < currentTime) {
          store.dispatch(logoutUser())
        }
      }
    }
    fetchUser()
  }, [])

  return (
    <Provider store={store}>
      <MathquillRefProvider>
        <PublicTabsProvider>
          <Router>
            <Switch>

              <Route exact path="/">
                <Navbar />
                <Landing />
              </Route>

              <Route exact path="/admin">
                <AdminPage />
              </Route>

              <Route exact path="/share/:id">
                <Navbar />
                <Landing />
              </Route>
            </Switch>
          </Router>
        </PublicTabsProvider>
      </MathquillRefProvider>
    </Provider>
  )
}

export default App
