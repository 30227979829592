import {
  GET_LOG_IN_ERRORS,
  GET_REGISTER_ERRORS,
  GET_CHANGE_PASSWORD_ERRORS,
} from '../actions/types'

const initialState = {}

export default function (state = initialState, action) {
  switch (action.type) {
  case GET_REGISTER_ERRORS:
    return { ...state, register: action.payload }
  case GET_LOG_IN_ERRORS:
    return { ...state, log_in: action.payload }
  case GET_CHANGE_PASSWORD_ERRORS:
    return { ...state, change_password: action.payload }
  default:
    return state
  }
}
