import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'semantic-ui-react'

import { loginUser } from 'actions/authActions'

import classnames from 'classnames'

import './Login.scss'

class Login extends Component {
  constructor() {
    super()
    this.state = {
      email: '',
      password: '',
      errors: {},
      open: false,
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      })
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }

  onSubmit = (e) => {
    e.preventDefault()
    const userData = {
      email: this.state.email,
      password: this.state.password,
    }
    this.props.loginUser(userData)
  }

  onClose = () => {
    this.setState({
      email: '',
      password: '',
      errors: {},
      open: false,
    })
  }

  render() {
    const { errors } = this.state
    return (
      <Modal
        onClose={this.onClose}
        onOpen={() => this.setState({ open: true })}
        open={this.state.open}
        trigger={<button className="btn btn__primary login__btn">Log in</button>}
      >
        <div>
          <form
            className="ui form"
            noValidate
            onSubmit={this.onSubmit}
            style={{ margin: '70px' }}
          >
            <h4 className="ui dividing header">Log in</h4>
            <div className="field">
              <label htmlFor="email">Email</label>
              <input
                onChange={this.onChange}
                value={this.state.email}
                error={errors.email}
                id="email"
                type="email"
                className={classnames('', {
                  invalid: errors.email || errors.emailnotfound,
                })}
              />
              <span style={{ color: 'red' }}>
                {errors.email}
                {errors.emailnotfound}
              </span>
            </div>
            <div className="field">
              <label htmlFor="password">Password</label>
              <input
                onChange={this.onChange}
                value={this.state.password}
                error={errors.password}
                id="password"
                type="password"
                className={classnames('', {
                  invalid: errors.password || errors.passwordincorrect,
                })}
              />
              <span style={{ color: 'red' }}>
                {errors.password}
                {errors.passwordincorrect}
              </span>
            </div>
            <div>
              <button
                style={{
                  width: '150px',
                  borderRadius: '3px',
                  letterSpacing: '1.5px',
                  marginTop: '1rem',
                }}
                type="submit"
                className="ui button"
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors.log_in,
})

export default connect(mapStateToProps, { loginUser })(Login)
