import React from 'react'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import DocumentDetails from './DocumentDetails/DocumentDetails'
import Download from './Download/Download'

import styles from './Sidebar.module.css'
import './Sidebar.scss'

import $ from 'jquery'
import { Segment } from 'semantic-ui-react'
import PublicTabList from 'components/TabList/PublicTabList/PublicTabList'

function Sidebar() {
  const buttonOnClick = async () => {
    $('#target').toggleClass('sidebar--hide')
    $('#sidebarBtn').toggleClass('sidebar__btn--flip')
    $('#main').toggleClass(styles.stretch)
  }

  return (
    <div id="target" className="sidebar sidebar--hide">
      <button
        id="sidebarBtn"
        className="sidebar__btn sidebar__btn--flip"
        onClick={buttonOnClick}
      >
        <ArrowForwardIosIcon style={{ fontSize: 'x-large' }} />
      </button>
      <div className="sidebar__container">
        <DocumentDetails />
        <hr className="sidebar__hrule" />
        <Download />
        <Segment inverted>
          <PublicTabList />
        </Segment>
      </div>
    </div>
  )
}

export default Sidebar
