import React from 'react'
import { connect } from 'react-redux'
import { openTabs } from 'actions/latexActions'
import { v4 as uuidv4 } from 'uuid'
import { deletePublicTabs, savePublicTabs, saveTabs, updatePublicTabs } from 'apis/requests'
import { usePublicTabsContext } from 'components/Providers/PublicTabsProvider'
import AccordionTabs from 'components/Accordion/AccordionTabs'
import SingleTabRow from 'components/TabList/SingleTabRow/SingleTabRow'

const PublicTabList = ({ openTabs, user, currentTab, latex, currentTabKey, globalSavedTabs }) => {
  const { publicTabs, setPublicTabs } = usePublicTabsContext()
  const saveCurrentTab = () => {
    const newTabId = uuidv4()
    const tabForSaving = { ...currentTab, tabId: newTabId, author: user.email }
    savePublicTabs(tabForSaving)
      .then(({ data }) => setPublicTabs(data))
      .catch(() => console.error('Save current tab to DB going wrong'))
  }

  const saveAllTabs = async () => {
    const tabsForSaving = Object.keys(latex.tabs).map((tabId) => {
      const newTabId = uuidv4()
      return { ...latex.tabs[tabId], tabId: newTabId, author: user.email }
    })
    console.log(tabsForSaving)
    savePublicTabs({
      accordionName: 'New accordion',
      accordionId: uuidv4(),
      isAccordion: true,
      tabs: tabsForSaving
    })
      .then(({ data }) => {
        setPublicTabs(data)
      })
      .catch(() => console.error('Save all tabs to DB going wrong'))
  }

  const removeTabOrTabs = (id) => {
    if (window.confirm('Please confirm if you really want to remove')) {
      deletePublicTabs(id)
        .then(({ data }) => setPublicTabs(data))
        .catch(() => console.error('Remove tab from DB going wrong'))
    }
  }

  const removeGroupTabs = (e, id) => {
    e.stopPropagation()
    removeTabOrTabs(id)
  }

  const handleOpenTabs = (e, tabs) => {
    e.stopPropagation()
    openTabs(tabs)
  }

  const handleUpdate = (tabId, workSheetId) => {
    const workSheet = {
      ...latex.tabs[tabId],
    }
    updatePublicTabs(workSheetId, { workSheet })
      .then(({ data }) => setPublicTabs(data))
      .catch(() => console.error('Update tab from DB going wrong'))
  }

  const tabIsOpened = (tab) => tab.tabId === currentTab?.tabId

  return (
    <div>
      <div className="tab-list__actions">
        {user.editor && (
          <>
            <button className="btn btn__primary" onClick={saveCurrentTab}>Save current tab</button>
            <button className="btn btn__primary" onClick={saveAllTabs}>Save All</button>
          </>
        )}
      </div>

      {publicTabs.length > 0 && publicTabs.map((item, index) => (
        item.workSheet.isAccordion
          ?
          <AccordionTabs
            accordionId={item.workSheet.accordionId}
            accordionName={item.workSheet.accordionName}
            accordionIndex={index}
            isPublic
            key={index}
            removeGroupTabs={e => removeGroupTabs(e, item._id)}
            tabs={item.workSheet.tabs}
            tabIsOpened={tabIsOpened}
            workSheetId={item._id}
          />
          : (
            <SingleTabRow
              key={index}
              allTabs={latex.tabs}
              isOpened={tabIsOpened(item.workSheet)}
              isPublic
              savedTab={item.workSheet}
              onOpen={(e) => handleOpenTabs(e, [item.workSheet])}
              onDelete={() => removeTabOrTabs(item._id)}
              onUpdate={() => handleUpdate(item.workSheet.tabId, item._id)}
            />
          )
      ))}
    </div>
  )
}

const mapStateToProps = (state) => {
  const currentTabIndex = state.latex.currentTab
  const currentTabKey = Object.keys(state.latex.tabs)[currentTabIndex]
  return {
    currentTab: state.latex.tabs[currentTabKey],
    currentTabKey,
    latex: state.latex,
    user: state.auth.user,
    globalSavedTabs: state.latex.savedTabs,
  }
}

const mapActionsToProps = {
  openTabs,
}

export default connect(mapStateToProps, mapActionsToProps)(PublicTabList)
