import { useState, useEffect } from 'react'

export const useMobileDetector = (): boolean => {
  const [ isMobile, setIsMobile ] = useState<boolean>(window.matchMedia('(max-width: 640px)').matches)

  useEffect(() => {
    const mediaQueryList: MediaQueryList = window.matchMedia('(max-width: 640px)')
    const handleChange = (event: MediaQueryListEvent) => {
      setIsMobile(event.matches)
    }
    mediaQueryList.addEventListener('change', handleChange)
    return () => {
      mediaQueryList.removeEventListener('change', handleChange)
    }
  }, [])

  return isMobile
}
