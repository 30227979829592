export const convertToLatex = (pageTitle, pageAuthor, tabs) => {
  const pageLatex = []
  const sectionList = []

  for (const tab of Object.values(tabs)) {
    const allSingleFalse = Object.values(tab.equations).every(
      (e) => e.showEqual === false && e.equationMode === 0,
    )

    const latex = []

    if (!allSingleFalse) {
      latex.push('\\begin{dgroup*}\n')
    }

    for (const equation of Object.values(tab.equations)) {
      if (allSingleFalse) {
        if (equation.latexSingle !== '') {
          latex.push(
            `\\begin{flushleft}$${equation.latexSingle}$\\end{flushleft}\n`,
          )
        }
      } else if (equation.equationMode === 0) {
        // Single-sided
        if (equation.showEqual === true) {
          latex.push(`\t\\begin{dmath}${equation.latexSingle}\\end{dmath}\n`)
        } else if (equation.latexSingle !== '') {
          latex.push(
            `\t\\begin{dsuspend}$${equation.latexSingle}$\\end{dsuspend}\n`,
          )
        }
      } else {
        latex.push(
          `\t\\begin{dmath}${equation.latexLeft} ${equation.currentSign} ${equation.latexRight}\\end{dmath}\n`,
        )
      }
    }

    if (!allSingleFalse) {
      latex.push('\\end{dgroup*}\n')
    }

    sectionList.push(tab.tabTitle)
    pageLatex.push(latex)
  }

  return (
    generateHead(pageTitle, pageAuthor)
    + generateBody(pageLatex, sectionList)
    + generateTail()
  )
}

export const generateBody = (pageLatex, sectionList) => {
  let fullBody = ''

  for (const [ i, tab ] of pageLatex.entries()) {
    const newLatex = tab.slice(0, tab.length)

    let fullFormula = ''
    newLatex.map((formula) => (fullFormula = `${fullFormula}${formula}`))

    let individualBody = ''
    if (fullFormula !== '') {
      individualBody = `\\section*{${sectionList[i]}}
\\setcounter{equation}{0}
${fullFormula.substring(0, fullFormula.length - 1)}
`
    } else {
      individualBody = `\\section*{${sectionList[i]}}
`
    }

    fullBody = `${fullBody}\n${individualBody}`
  }

  return fullBody
}

export const generateHead = (title, author) => `\\documentclass{article}
\\usepackage{amsmath, amssymb, breqn}
\\usepackage[margin=1in]{geometry}
\\usepackage[utf8]{inputenc}

\\title{\\vspace{-1cm}${title.replace(/[^a-zA-Z0-9 ]/g, '')}}
\\author{${author.replace(/[^a-zA-Z0-9 ]/g, '')}}
\\date{\\today}

\\begin{document}

\\maketitle

\\intereqskip=10pt
`

export const generateTail = () => `
\\end{document}
`
