import React, { useState } from 'react'
import logo from 'logos/typeMathLogo_blue.svg'
import { connect } from 'react-redux'
import Register from '../auth/Register'
import Login from '../auth/Login'
import './Navbar.scss'
import CloseIcon from '@material-ui/icons/Close'
import MenuOpenIcon from '@material-ui/icons/MenuOpen'

import { changeTheme } from 'actions/latexActions'
import { logoutUser, setCurrentUser } from 'actions/authActions'
import styles from './Navbar.module.css'
import { updateUser } from 'apis/requests'

function Navbar(props) {
  const [ sideNavOpen, setSideNavOpen ] = useState(false)

  const cancelPremium = async () => {
    const res = await updateUser({ email: props.auth.user.email, premium: false })
    if (res.status === 200) {
      props.setCurrentUser(res.data.user)
    } else {
      alert('Failed to cancel premium. Please try again later or contact administrator.')
    }
  }

  const handleChangeTheme = () => {
    props.changeTheme()
  }

  const handleLogout = () => {
    props.logoutUser()
  }

  const updateToPremiumUser = async () => {
    const res = await updateUser({ email: props.auth.user.email, premium: true })
    if (res.status === 200) {
      props.setCurrentUser(res.data.user)
    } else {
      alert('Failed to upgrade account. Please try again later or contact administrator.')
    }
  }

  const renderUser = () => {
    if (props.auth.isAuthenticated) {
      return (
        <div className={`navbar__user ${props.latex.whiteMode ? 'navbar__user--white-mode' : ''}`}>
          <p>
            Hi!
            {props.auth.user.name}
          </p>
          {!props.auth.user.premium && (
            <button className="btn btn__primary" onClick={updateToPremiumUser}>Upgrade to premium</button>
          )}
          {!props.auth.user.premium && <p className="btn__caption">Free for a limited time only</p>}
          {props.auth.user.premium && <button className="btn btn__primary" onClick={cancelPremium}>Cancel premium</button>}
          <div style={{ marginTop: 6 }} />
          <button onClick={handleLogout} className="btn btn__primary">Log out</button>
        </div>
      )
    }
  }

  const renderAuthBtn = () => {
    if (!props.auth.isAuthenticated) {
      return (
        <>
          <Register />
          <Login />
        </>
      )
    }
  }

  const renderNavMenu = () => (
    <div className="navbar__side-menu">
      <span className="navbar__close-icon-container">
        <CloseIcon onClick={() => setSideNavOpen(false)} />
      </span>
      {renderUser()}
      {renderAuthBtn()}
      {!props.auth.user.premium && (
        <p className="navbar__copy">
          Premium accounts can:
            - save tabs to the cloud so they can be opened from any computer or browser
            - share copies of saved tabs with other premium users
        </p>)}
    </div>
  )

  return (
    <div className={styles.container} style={{ backgroundColor: props.latex.whiteMode ? 'white' : '' }}>
      <div className="navbar__left">
        <img className="navbar__logo" src={logo} alt="logo" />
      </div>
      <div className="navbar__info">

        <a className={styles.downloadbtn} href="https://www.tutortree.com.au" target="_blank" rel="noreferrer">
          TutorTree
        </a>
        <a className={styles.downloadbtn} href="https://youtu.be/jxtmi5ih3tE" target="_blank" rel="noreferrer">
          How To TypeMaths
        </a>
        <button className={styles.downloadbtn} onClick={handleChangeTheme}>
          Change theme
        </button>

        <MenuOpenIcon
          className="navbar__menu"
          onClick={() => setSideNavOpen(true)}
        />
        {sideNavOpen ? renderNavMenu() : null}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  latex: state.latex,
  auth: state.auth,
})

export default connect(mapStateToProps, {
  changeTheme,
  logoutUser,
  setCurrentUser,
})(Navbar)
