import React from 'react'

import { Button, Popup } from 'semantic-ui-react'
import Equation1 from './images/equation1.svg'
import Equation2 from './images/equation2.svg'
import Equation3 from './images/equation3.svg'
import Equation4 from './images/equation4.svg'
import Equation5 from './images/equation5.svg'
import Equation6 from './images/equation6.svg'
import styles from './Instruction.module.css'

class Instruction extends React.Component {
  state = { isOpen: false }

  handleOpen = () => {
    this.setState({ isOpen: true })
  }

  handleClose = () => {
    this.setState({ isOpen: false })
  }

  renderInstructions = () => (
    <div>
      <div style={{ overflowY: 'auto', height: '400px', paddingBottom: '0' }}>
        <h3 style={{ fontSize: '16px' }}>Instructions</h3>
        <ul
          style={{
            listStyleType: 'none', padding: '0', marginRight: '5px', fontSize: '12px',
          }}
          className="instructionList"
        >
          <li>Press [Enter] to duplicate the line you're on.</li>
          <li>Press [Esc] to delete the line you're on.</li>
          <li>hold control and press the up/down arrow keys to move from one equation to another.</li>
          <li>In double-sided equation mode, hold control and press the left/right arrow keys to move from one side to the other.</li>
          <li>hold control and press [Enter] to change equation type.</li>
          <li>For a multiplication symbol press * or type \ast and press space.</li>
          <li>For a division symbol type \div and press space.</li>
          <li>
            To create a fraction, press /. You can use brackets to create fractions with multiple terms in the numerator or denominator. For example,
            <img src={Equation1} style={{ height: 32 }} className={styles.instructionImage} alt="(x+1)/(x+2)" />
            {' '}
            is typed (x+1)/(x+2). You can remove the brackets, if you want, once you've created the fraction. You can also create a fraction by typing \frac and pressing space.
          </li>
          <li>
            For powers, use the ^ symbol. For example,
            <img src={Equation2} style={{ height: 16 }} className={styles.instructionImage} alt="x^2" />
            {' '}
            is typed x^2. You can have fractions in your powers. Press the right arrow key to move out of the power.
          </li>
          <li>For plus/minus, type \pm and press space. You can also type \mp and press space to reverse the symbols.</li>
          <li>
            For subscript, use the _ key. For example,
            <img src={Equation3} style={{ height: 16 }} className={styles.instructionImage} alt="x^2" />
            {' '}
            is typed x_n.
          </li>
          <li>
            For
            <span className="mq-nonSymbola" mathquill-command-id="151">π</span>
            {' '}
            type \pi and press space.
          </li>
          <li>
            For a square root type \sqrt and press space.
            <img src={Equation4} style={{ height: 16 }} className={styles.instructionImage} alt="The square root of x" />
            {' '}
            is typed \sqrt [space] x. You can press the right arrow key to move out of the square root.
          </li>
          <li>For a cursive f, as in f(x), type \f and press space.</li>
          <li>
            For
            <img src={Equation5} style={{ height: 16 }} className={styles.instructionImage} alt="Integral" />
            , type \int and press space. You will be able to add upper and lower bounds if you want.
          </li>
          <li>You can create upper and lower bounds on square brackets by using the power (^) command and the subscript (_) command. You may need to play around to get it right!</li>
          <li>
            For a
            <img src={Equation6} style={{ height: 16 }} className={styles.instructionImage} alt="Sum" />
            {' '}
            symbol, type \sum and press space. You will be able to add upper and lower bounds.
          </li>
          <li>For a binomial expression type \binom and press space or type \choose and press space.</li>
          <li>Greek characters: \alpha:  α, \beta:  β, \gamma:  γ, \delta:  δ</li>
          <li>For a therefore symbol, type \therefore and press space.</li>
          <li>For (many many many) more symbols, visit https://inspera.atlassian.net/wiki/spaces/KB/pages/62062830/MathQuill+symbols</li>
        </ul>
        <div style={{
          height: '50px', background: '-webkit-linear-gradient(top, rgba(255,255,255,0), rgba(255,255,255, 1))', position: 'sticky', bottom: '0',
        }}
        />
      </div>
    </div>
  )

  render() {
    return (
      <Popup
        trigger={<Button circular style={{ background: '#DDEAFF' }} icon="info" />}
        content={this.renderInstructions}
        on="click"
        open={this.state.isOpen}
        onClose={this.handleClose}
        onOpen={this.handleOpen}
        basic
      />
    )
  }
}

export default Instruction
