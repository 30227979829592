import evaluatex from 'evaluatex/dist/evaluatex'

export const evaluateLatex = (latex: string | number) => {
  return new Promise((resolve, reject) => {
    try {
      // there is error if latex is number, so need to convert to string
      const stringfiedLatex = latex.toString()

      // TODO: find a better way to parse pi
      // hardcoded pi in equation
      const parsedLatex = stringfiedLatex.replace('\\pi', '3.1415926')

      resolve(evaluatex(parsedLatex)())
    }
    catch (error) {
      reject(error)
    }
  })
}
