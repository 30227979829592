import React from 'react'
import Main from './Main/Main'
import Sidebar from './Sidebar/Sidebar'

function Landing() {
  return (
    <div style={{ display: 'flex', background: '#2F2F39', paddingTop: '10vh' }}>
      <Main />
      <Sidebar />
    </div>
  )
}

export default Landing
