import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Checkbox } from 'semantic-ui-react'
import './UserList.scss'
import { GET_ALL_USERS } from 'apis/urls'
import { updateUser } from 'apis/requests'

function UserList() {
  const [ users, setUsers ] = useState([])

  useEffect(() => {
    axios.get(GET_ALL_USERS)
      .then((res) => setUsers(res.data))
      .catch((err) => console.error(err))
  }, [])

  const toggleAuthority = async (event, index, type) => {
    const checkboxValue = !event.target.previousSibling.checked
    const res = await updateUser({ email: users[index].email, [type]: checkboxValue })
    if (res.status === 200) {
      const updatedUsers = [...users]
      updatedUsers[index][type] = checkboxValue
      setUsers(updatedUsers)
    } else {
      console.error(res)
    }
  }

  const renderUsers = () => {
    if (users.length) {
      return (
        <table className="user-list__table">
          <tr>
            <th>User email</th>
            <th>Admin</th>
            <th>Paid</th>
            <th>Premium</th>
            <th>Editor</th>
          </tr>
          {users.map((user, i) => (
            <tr key={i}>
              <td>{user.email}</td>
              <td><Checkbox toggle checked={user.admin} onClick={(event) => toggleAuthority(event, i, 'admin')} /></td>
              <td><Checkbox toggle checked={user.paid} onClick={(event) => toggleAuthority(event, i, 'paid')} /></td>
              <td><Checkbox toggle checked={user.premium} onClick={(event) => toggleAuthority(event, i, 'premium')} /></td>
              <td><Checkbox toggle checked={user.editor} onClick={(event) => toggleAuthority(event, i, 'editor')} /></td>
            </tr>
          ))}
        </table>
      )
    }
  }

  return (
    <div className="user-list">
      {renderUsers()}
    </div>
  )
}

export default UserList
