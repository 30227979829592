import React from 'react'
import { Container, Key as StyledKey } from './Keyboard.styles'

interface Props {
  children: React.ReactElement
  onClick?: (e: React.MouseEvent) => void
  props?: React.Props<any>
}

const Key = ({ children, ...props }: Props) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
  }
  return (
    <StyledKey onMouseDown={handleClick} {...props}>{children}</StyledKey>
  )
}

export default Key
