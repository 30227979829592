import styled from 'styled-components'
import { TabPanel as ReactTabPanel } from 'react-tabs'
import { Icon } from 'semantic-ui-react'

const keyboardHeight = '300px'

export const KeyboardContainer = styled.div<{hide?: boolean}>`
  height: ${props => props.hide ? '0' : keyboardHeight};
  overflow: hidden;
  transition: height 0.5s ease-in-out;
`

export const TabPanel = styled(ReactTabPanel)<{lowHeight?: boolean}>`
  overflow-y: scroll;
  max-height: ${props => props.lowHeight ? `calc(77vh - ${keyboardHeight})` : '77vh'};
  transition: max-height 0.5s ease-in-out;
`

export const StyledIcon = styled(Icon)`
  cursor: pointer;
`
