import {
  ADD_DUPLICATE_EQUATION,
  ADD_NEW_EQUATION,
  REMOVE_EQUATION,
  REMOVE_TAB,
  ADD_TAB,
  UPDATE_CURRENT_TAB,
  DUPLICATE_TAB,
  UPDATE_CURRENT_SIGN,
  UPDATE_CURRENT_TAB_ONLY,
  UPDATE_EQUATION_LOCK,
  UPDATE_EQUATION_MODE,
  UPDATE_LATEX_LEFT,
  UPDATE_LATEX_RIGHT,
  UPDATE_LATEX_SINGLE,
  UPDATE_SHOW_EQUAL,
  UPDATE_PAGE_TITLE,
  UPDATE_PAGE_AUTHOR,
  UPDATE_CURRENT_EQUATION,
  UPDATE_TAB_TITLE,
  RESET_TABS,
  CHANGE_THEME,
  OPEN_TABS,
  UNDO,
  UPDATE_FOCUSED_EQUATION_KEY,
  UPDATE_SAVED_TABS,
  UPDATE_TAB,
  UPDATE_TAB_STATUS,
} from './types'

export const addDuplicateEquation = (payload) => ({ type: ADD_DUPLICATE_EQUATION, payload })

export const addNewEquation = (payload) => ({ type: ADD_NEW_EQUATION, payload })

export const updateCurrentEquation = (payload) => ({ type: UPDATE_CURRENT_EQUATION, payload })

export const updateCurrentTabOnly = (payload) => ({ type: UPDATE_CURRENT_TAB_ONLY, payload })

export const updateCurrentTab = (payload) => ({ type: UPDATE_CURRENT_TAB, payload })

export const removeEquation = (payload) => ({ type: REMOVE_EQUATION, payload })

export const duplicateTab = (payload) => ({ type: DUPLICATE_TAB, payload })

export const addTab = (payload) => ({ type: ADD_TAB, payload })

export const removeTab = (payload) => ({ type: REMOVE_TAB, payload })

export const updateCurrentSign = (payload) => ({ type: UPDATE_CURRENT_SIGN, payload })

export const updateEquationLock = (payload) => ({ type: UPDATE_EQUATION_LOCK, payload })

export const updateEquationMode = (payload) => ({ type: UPDATE_EQUATION_MODE, payload })

export const updateLatexLeft = (payload) => ({ type: UPDATE_LATEX_LEFT, payload })

export const updateLatexRight = (payload) => ({ type: UPDATE_LATEX_RIGHT, payload })

export const updateLatexSingle = (payload) => ({ type: UPDATE_LATEX_SINGLE, payload })

export const updateShowEqual = (payload) => ({ type: UPDATE_SHOW_EQUAL, payload })

export const updatePageTitle = (payload) => ({ type: UPDATE_PAGE_TITLE, payload })

export const updatePageAuthor = (payload) => ({ type: UPDATE_PAGE_AUTHOR, payload })

export const updateTabTitle = (payload) => ({ type: UPDATE_TAB_TITLE, payload })

export const resetTabs = (payload) => ({ type: RESET_TABS, payload })

export const changeTheme = (payload) => ({ type: CHANGE_THEME, payload })

export const openTabs = (payload) => ({ type: OPEN_TABS, payload })

export const undo = (payload) => ({ type: UNDO, payload })

export const updateTab = (payload) => ({ type: UPDATE_TAB, payload })

export const updateTabStatus = (payload) => ({ type: UPDATE_TAB_STATUS, payload })

export const updateSavedTabs = (payload) => ({ type: UPDATE_SAVED_TABS, payload })

export const updateFocusedEquationKey = (payload) => ({ type: UPDATE_FOCUSED_EQUATION_KEY, payload })

