import axios from 'axios'
import jwt_decode from 'jwt-decode'
import setAuthToken from '../utils/setAuthToken'
import {
  GET_LOG_IN_ERRORS,
  GET_REGISTER_ERRORS,
  GET_CHANGE_PASSWORD_ERRORS,
  SET_CURRENT_USER,
  USER_LOADING,
} from './types'

export const registerUser = (userData) => async (dispatch) => {
  const res = await axios.post('/api/users/register', userData)
    .catch((err) => dispatch({
      type: GET_REGISTER_ERRORS,
      payload: err.response.data,
    }))
  return res
}

export const changePassword = (userData) => async (dispatch) => {
  const res = await axios
    .post('/api/users/changepassword', userData)
    .catch((err) => dispatch({
      type: GET_CHANGE_PASSWORD_ERRORS,
      payload: err.response.data,
    }))
  return res
}

export const loginUser = (userData) => async (dispatch) => {
  await axios
    .post('/api/users/login', userData)
    .then((res) => {
      const { token } = res.data
      localStorage.setItem('jwtToken', token)
      setAuthToken(token)
      const decoded = jwt_decode(token)
      dispatch(setCurrentUser(decoded))
    })
    .catch((err) => dispatch({
      type: GET_LOG_IN_ERRORS,
      payload: err.response.data,
    }))
}

export const setCurrentUser = (decoded) => ({
  type: SET_CURRENT_USER,
  payload: decoded,
})

export const setUserLoading = () => ({
  type: USER_LOADING,
})

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('jwtToken')
  setAuthToken(false)
  dispatch(setCurrentUser({}))
  window.location.href = './'
}
