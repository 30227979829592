import React from 'react'
import { connect } from 'react-redux'

import {
  updateEquationMode,
  updateShowEqual,
  updateLatexSingle,
} from 'actions/latexActions'

import Checkbox from '@material-ui/core/Checkbox'
import { withStyles } from '@material-ui/core/styles'

import $ from 'jquery'
import SingleSidedEquation from './SingleSidedEquation/SingleSidedEquation'
import TwoSidedEquation from './TwoSidedEquation/TwoSidedEquation'

const checkBoxStyles = () => ({
  root: {
    left: '-5px',
    color: '#b9c1d6',
    padding: '0',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
    '&$checked': {
      color: '#b9c1d6',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    },
  },
  checked: {},
})

const CustomCheckbox = withStyles(checkBoxStyles)(Checkbox)

class EquationField extends React.Component {
  state = {
    equationMode: this.props.equationMode,
    showEqual: this.props.showEqual,
  }

  onChangeEquationMode = async (equationMode) => {
    await this.setState({ equationMode })

    await this.props.updateEquationMode({
      equationMode: this.state.equationMode,
      tabKey: this.props.tabKey,
      equationKey: this.props.equationKey,
    })
  }

  renderCounter = () => {
    if (this.props.counter) {
      return (
        <p>
          {this.props.counter}
          .
        </p>
      )
    }
  }

  renderEquation = (equationMode) => {
    if (equationMode === 0) {
      return (
        <SingleSidedEquation
          tabKey={this.props.tabKey}
          equationKey={this.props.equationKey}
          onChangeEquationMode={this.onChangeEquationMode}
        />
      )
    }
    return (
      <TwoSidedEquation
        tabKey={this.props.tabKey}
        equationKey={this.props.equationKey}
        onChangeEquationMode={this.onChangeEquationMode}
      />
    )
  }

  handleOnChange = async (e) => {
    const show = !this.props.tabs[this.props.tabKey].equations[
      this.props.equationKey
    ].showEqual

    this.props.updateShowEqual({
      tabKey: this.props.tabKey,
      equationKey: this.props.equationKey,
      showEqual: show,
    })

    const latex = this.props.tabs[this.props.tabKey].equations[
      this.props.equationKey
    ].latexSingle

    if (!show && latex[0] === '=') {
      await this.props.updateLatexSingle({
        latexSingle: latex.replaceAll('=', ''),
        tabKey: this.props.tabKey,
        equationKey: this.props.equationKey,
      })
    } else if (show && latex[0] !== '=') {
      await this.props.updateLatexSingle({
        latexSingle: `=${latex}`,
        tabKey: this.props.tabKey,
        equationKey: this.props.equationKey,
      })
    }
  }

  handleOnChangeMode = async (e) => {
    let mode = this.props.tabs[this.props.tabKey].equations[
      this.props.equationKey
    ].equationMode

    if (mode === 1) {
      mode = 0
    } else if (mode === 0) {
      mode = 1
    }

    const nextElement = e.target.closest('#eq-field')

    await this.setState({ equationMode: mode })

    await this.props.updateEquationMode({
      equationMode: this.state.equationMode,
      tabKey: this.props.tabKey,
      equationKey: this.props.equationKey,
    })

    $(nextElement).find('textarea')[0].focus()

    /* if(this.state.equationMode === 1){
      $(`#${this.props.equationKey}-eq-checkbox-showequal`)[0].setAttribute('disabled', 'true')
    }else{
      $(`#${this.props.equationKey}-eq-checkbox-showequal`)[0].removeAttribute('disabled')
    } */
  }

  renderDropdownText = (equationMode) => {
    if (equationMode === 0) {
      return 'Single'
    }
    return 'Double'
  }

  render() {
    return (
      <div
        style={{
          width: '100%',
          padding: '2px 2px 2px 12px',
          display: 'flex',
          flexDirection: 'column',
        }}
        onMouseDown={(e) => e.preventDefault()}
        id="eq-field"
      >
        <div style={{ display: 'flex' }}>
          <div
            style={{ overflow: 'visible', position: 'absolute' }}
            id={`${this.props.equationKey}-eq-checkbox`}
          >
            <span
              style={{
                fontSize: '10px',
                paddingLeft: '5px',
                color: '#808CAF',
                fontWeight: 'bold',
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '-webkit-fill-available',
              width: '-moz-available',
              paddingLeft: '45px',
            }}
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            id={`${this.props.equationKey}-eq-checkbox`}
          >
            {/* <div>
              <CustomCheckbox
                id={`${this.props.equationKey}-eq-checkbox-showequal`}
                checked={
                  this.props.tabs[this.props.tabKey].equations[
                    this.props.equationKey
                  ].showEqual
                }
                onChange={this.handleOnChange}
              />
              </div> */}
            <div>
              <CustomCheckbox
                id={`${this.props.equationKey}-eq-checkbox-equationmode`}
                checked={
                  this.props.tabs[this.props.tabKey].equations[
                    this.props.equationKey
                  ].equationMode === 1
                }
                onChange={this.handleOnChangeMode}
              />
            </div>
          </div>
          <div
            style={{
              width: '35px',
              margin: 'auto',
              color: '#B9C1D6',
              fontWeight: 'bold',
            }}
          >
            {this.renderCounter()}
          </div>
          {this.renderEquation(this.state.equationMode)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  tabs: state.latex.tabs,
})

export default connect(mapStateToProps, {
  updateEquationMode,
  updateShowEqual,
  updateLatexSingle,
})(EquationField)
