// inspired by https://github.com/serrexlabs/react-confirm-box

import React, { useEffect, useState } from 'react'
import ReactDOM, { createPortal } from 'react-dom'
import { Icon, Button } from 'semantic-ui-react'

import './CustomConfirm.scss'

const Modal = ({ children, mountElement }) => {
  useEffect(() => () => mountElement.remove())

  return createPortal(children, mountElement)
}

function ConfirmModal({
  message, options, resolver, mountElement,
}) {
  const [ isOpened, setIsOpened ] = useState(true)

  const handleConfirm = () => {
    setIsOpened(false)
    resolver(true)
  }

  const handleCancel = () => {
    setIsOpened(false)
    resolver(false)
  }

  const renderModal = () => {
    if (!options?.render) {
      return (
        <>
          <div className="confirm-modal__container">
            <span className="confirm-modal__message">{message}</span>
            <div className="confirm-modal__actions">
              <Button
                basic
                className="confirm-modal__cancel"
                role="confirm-button"
                color="red"
                inverted
                onClick={handleCancel}
              >
                <Icon name="remove" />
                {' '}
                {options?.labels?.cancel ? options?.labels?.cancel : 'No'}
              </Button>
              <Button
                color="green"
                inverted
                className="confirm-modal__confirm"
                role="cancel-button"
                onClick={handleConfirm}
              >
                <Icon name="checkmark" />
                {' '}
                {options?.labels?.confirm ? options?.labels?.confirm : 'Yes'}
              </Button>
            </div>
          </div>
          <div className="confirm-modal__overlay" />
        </>
      )
    }
    /**
         * Customer rendered component
         * Pass as part of options
         * e.g.
         * render: (message, handleConfirm, handleCancl) => {
         *      return ReactDOM
         * }
         */
    return options.render(message, handleConfirm, handleCancel)
  }

  if (!options?.render) {
    return (
      isOpened
        ? (
          <Modal mountElement={mountElement}>
            {renderModal()}
          </Modal>
        )
        : null
    )
  }
}

/**
 *
 * @param {string} message
 * @param {
 *    mountElement: NodeElement
 *    labels?: { confirm: string; cancel: string };
 *    classNames?: ClassNames; TODO hardcoded now
 *    closeOnOverlayClick?: boolean;  TODO
 *    render?: (message: string, onConfirm: () => void, onCancel: () => void) => Element;
 * } options
 */
const confirm = async (message, options) => new Promise((resolve) => {
  const el = document.createElement('div')
  const rootDom = document.getElementById('root')

  el.className = 'confirm-modal'
  el.setAttribute('id', 'confirm-modal')

  rootDom.appendChild(el)

  const ConfirmPortal = React.createElement(ConfirmModal, {
    resolver: resolve,
    message,
    options,
    mountElement: el,
  })
  ReactDOM.render(ConfirmPortal, el)
})

export { confirm }
