import { v4 as uuidv4 } from 'uuid'
import _ from 'lodash'
import {
  ADD_DUPLICATE_EQUATION,
  ADD_NEW_EQUATION,
  ADD_TAB,
  CHANGE_THEME,
  DUPLICATE_TAB,
  OPEN_TABS,
  REMOVE_EQUATION,
  REMOVE_TAB,
  RESET_TABS,
  UNDO,
  UPDATE_CURRENT_EQUATION,
  UPDATE_CURRENT_SIGN,
  UPDATE_CURRENT_TAB,
  UPDATE_CURRENT_TAB_ONLY,
  UPDATE_EQUATION_LOCK,
  UPDATE_EQUATION_MODE,
  UPDATE_FOCUSED_EQUATION_KEY,
  UPDATE_LATEX_LEFT,
  UPDATE_LATEX_RIGHT,
  UPDATE_LATEX_SINGLE,
  UPDATE_PAGE_AUTHOR,
  UPDATE_PAGE_TITLE,
  UPDATE_TAB_TITLE,
  UPDATE_SAVED_TABS,
  UPDATE_SHOW_EQUAL,
  UPDATE_TAB,
  UPDATE_TAB_STATUS,
} from '../actions/types'
import { addNewStateToHistory, undoHistory, HISTORY_ACTION_WHITELIST } from '../utils/historyUtils'

const INITIAL_TAB_ID = uuidv4()

const INITIAL_TAB = {
  [INITIAL_TAB_ID]: {
    tabTitle: 'Tab1',
    currentEquation: 0,
    tabId: INITIAL_TAB_ID,
    isChangedAfterSave: false,
    isSaved: false,
    equations: {
      [uuidv4()]: {
        equationMode: 0, // 0 isSingle, 1 isDouble
        latexSingle: '',
        latexLeft: '',
        latexRight: '',
        lock: false,
        currentSign: '=',
        showEqual: false,
      },
    },
  },
}

export let INITIAL_STATE = {
  pageTitle: 'Enter a title',
  pageAuthor: 'Enter an author',
  currentEquationKey: '',
  currentTab: 0,
  currentTabOnly: true,
  history: {
    initiated: false,
    historyState: [],
  },
  key: uuidv4(),
  savedTabs: [],
  tabs: INITIAL_TAB,
  whiteMode: false,
}

if (localStorage.getItem('latex') !== null) {
  INITIAL_STATE = JSON.parse(localStorage.getItem('latex'))
  INITIAL_STATE.currentTabOnly = false
  INITIAL_STATE.history = {
    initiated: false,
    historyState: [],
  }
}

const updateEquation = (state, payload, key) => ({
  ...state,
  tabs: {
    ...state.tabs,
    [payload.tabKey]: {
      ...state.tabs[payload.tabKey],
      equations: {
        ...state.tabs[payload.tabKey].equations,
        [payload.equationKey]: {
          ...state.tabs[payload.tabKey].equations[payload.equationKey],
          [key]: payload[key],
        },
      },
    },
  },
})

export default (state = INITIAL_STATE, { type, payload }) => {
  const updateReduxState = () => {
    switch (type) {
      case ADD_DUPLICATE_EQUATION:
        const array = Object.entries(state.tabs[payload.tabKey].equations)
        let index = 0
        // set index to new added equation index
        for (let i = 0; i < array.length; i++) {
          if (array[i][0] === payload.equationKey) {
            index = i
          }
        }

        // insert new equation to correct index in equation list
        const newArray = [
          ...array.slice(0, index + 1),
          [ uuidv4(), array[index][1] ],
          ...array.slice(index),
        ]

        const newEquations = Object.fromEntries(newArray)
        return {
          ...state,
          tabs: {
            ...state.tabs,
            [payload.tabKey]: {
              ...state.tabs[payload.tabKey],
              equations: newEquations,
            },
          },
        }
      case ADD_NEW_EQUATION:
        // Error handling
        if (
          !payload.hasOwnProperty('equationKey') ||
          !payload.hasOwnProperty('newEquation') ||
          !payload.hasOwnProperty('tabKey')
        ) {
          throw new Error('Missing property in payload')
        }

        // Insert new equation according current equation key
        const updatedEquations = {}
        for (const [ key, value ] of Object.entries(state.tabs[payload.tabKey].equations)) {
          Object.assign(updatedEquations, { [key]: value })
          if (key === payload.equationKey) {
            Object.assign(updatedEquations, { [uuidv4()]: payload.newEquation })
          }
        }

        return {
          ...state,
          tabs: {
            ...state.tabs,
            [payload.tabKey]: {
              ...state.tabs[payload.tabKey],
              equations: updatedEquations
            }
          }
        }
      case REMOVE_EQUATION:
        if (Object.keys(state.tabs[payload.tabKey].equations).length !== 1) {
          const equations = _.omit(
            state.tabs[payload.tabKey].equations,
            payload.equationKey,
          )
          return {
            ...state,
            tabs: {
              ...state.tabs,
              [payload.tabKey]: {
                ...state.tabs[payload.tabKey],
                equations,
              },
            },
          }
        }
        return { ...state }
      case ADD_TAB:
        const tabId = uuidv4()
        return {
          ...state,
          tabs: {
            ...state.tabs,
            [tabId]: {
              tabTitle: 'New Tab',
              currentEquation: 0,
              tabId,
              equations: {
                [uuidv4()]: {
                  equationMode: 0, // 0 isSingle, 1 isDouble
                  latexSingle: '',
                  latexLeft: '',
                  latexRight: '',
                  currentSign: '=',
                  showEqual: false,
                },
              },
            },
          },
        }
      case REMOVE_TAB:
        if (Object.keys(state.tabs).length !== 1) {
          const tabs = _.omit(state.tabs, payload.tabKey)
          return {
            ...state,
            tabs,
          }
        }
        return { ...state }
      case UNDO:
        // const newState = undoHistory()
        const { history: { historyState } } = state
        const copyHistoryState = [...historyState]
        let lastState
        if (historyState.length > 1) {
          copyHistoryState.pop()
          lastState = copyHistoryState.at(-1)
        }
        return lastState ? { ...lastState, history: { initiated: true, historyState: copyHistoryState } } : { ...state }
      case UPDATE_TAB:
        return {
          ...state,
          tabs: payload,
        }
      case DUPLICATE_TAB:
        const tabArray = Object.entries(state.tabs)
        let findTab = 0

        for (let j = 0; j < tabArray.length; j++) {
          if (tabArray[j][0] === payload.tabKey) {
            findTab = j
          }
        }

        const newTabArray = [
          ...tabArray.slice(0, findTab + 1),
          [ uuidv4(), tabArray[findTab][1] ],
          ...tabArray.slice(findTab),
        ]

        const newTabs = Object.fromEntries(newTabArray)
        return {
          ...state,
          tabs: newTabs,
        }
      case UPDATE_CURRENT_TAB_ONLY:
        return { ...state, currentTabOnly: payload.currentTabOnly }
      case UPDATE_CURRENT_TAB:
        return { ...state, currentTab: payload.currentTab }
      case UPDATE_CURRENT_EQUATION:
        return {
          ...state,
          tabs: {
            ...state.tabs,
            [payload.tabKey]: {
              ...state.tabs[payload.tabKey],
              currentEquation: payload.currentEquation,
            },
          },
        }
      case UPDATE_CURRENT_SIGN:
        return updateEquation(state, payload, 'currentSign')
      case UPDATE_EQUATION_LOCK:
        return updateEquation(state, payload, 'lock')
      case UPDATE_EQUATION_MODE:
        return updateEquation(state, payload, 'equationMode')
      case UPDATE_LATEX_LEFT:
        return updateEquation(state, payload, 'latexLeft')
      case UPDATE_LATEX_RIGHT:
        return updateEquation(state, payload, 'latexRight')
      case UPDATE_LATEX_SINGLE:
        return updateEquation(state, payload, 'latexSingle')
      case UPDATE_SHOW_EQUAL:
        return updateEquation(state, payload, 'showEqual')
      case UPDATE_PAGE_TITLE:
        return { ...state, pageTitle: payload }
      case UPDATE_PAGE_AUTHOR:
        return { ...state, pageAuthor: payload }
      case UPDATE_TAB_TITLE:
        return {
          ...state,
          tabs: {
            ...state.tabs,
            [payload.tabKey]: {
              ...state.tabs[payload.tabKey],
              tabTitle: payload.tabTitle,
            },
          },
        }
      case UPDATE_TAB_STATUS:
        const { tabKey, isTabChanged } = payload
        const updatedState = { ...state }
        if (updatedState.tabs[tabKey]) {
          updatedState.tabs[tabKey].isChangedAfterSave = isTabChanged
          return updatedState
        }
        return state

      case RESET_TABS:
        return {
          ...state,
          pageTitle: state.pageTitle,
          pageAuthor: state.pageAuthor,
          currentTab: 0,
          currentTabOnly: false,
          key: uuidv4(),
          tabs: INITIAL_TAB,
        }
      case CHANGE_THEME:
        return {
          ...state,
          whiteMode: !state.whiteMode,
        }
      case OPEN_TABS:
        const openingTabs = { ...state.tabs }
        payload.forEach((tab) => {
          const { tabTitle, equations, tabId } = tab
          if (state.tabs[tabId] === undefined) {
            Object.assign(openingTabs, {
              [tabId]: {
                tabTitle,
                currentEquation: 0,
                equations,
                tabId,
              },
            })
          }
        })
        const tabIndexAfterOpen = Object.keys(openingTabs).indexOf(payload[0].tabId)
        return {
          ...state,
          currentTab: tabIndexAfterOpen,
          tabs: openingTabs,
        }
      case UPDATE_FOCUSED_EQUATION_KEY:
        return {
          ...state,
          currentEquationKey: payload
        }
      case UPDATE_SAVED_TABS:
        return {
          ...state,
          savedTabs: payload,
        }
      default:
        return state
    }
  }

  const newState = updateReduxState()

  // TODO: Use debounce to prevent triggering too much times in a short time
  if (!state.history.initiated || (HISTORY_ACTION_WHITELIST.includes(type) && !_.isEqual(_.last(state.history.historyState), newState))) {
    newState.history.initiated = true
    newState.history.historyState.push(newState)
  }

  return newState
}
